import React, { FC } from "react";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import { motion, HTMLMotionProps } from "framer-motion";

export const getMidY = (offset = 0) => {
  if (typeof window !== "undefined") {
    const vh = window.innerHeight;
    return Math.round(vh / 2 + offset);
  } else return 0;
};

export interface DragTarget {
  size: string;
  y: number;
}
export const findClosestTarget = (
  lastY: number,
  targets: DragTarget[]
): DragTarget => {
  const closest = targets.reduce((a, b) => {
    return Math.abs(b.y - lastY) < Math.abs(a.y - lastY) ? b : a;
  });

  return closest;
};

const CustomPaperMotionDiv: FC<PaperProps & HTMLMotionProps<"div">> = (
  props: any
) => {
  return (
    <Paper component={motion.div} {...props} key={props.id}>
      {props.children}
    </Paper>
  );
};

export default CustomPaperMotionDiv;
