import React, { useState, useContext } from "react";
import { useTranslation } from "next-i18next";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Panel from "@Components/database/panel";
import FilterCards from "@Components/database/search/filterCards";
import QueryInput from "@Components/database/search/queryInput";
import { FormValue, formData, getState } from "@Lib/search";
import { PanelsContext, FeatureQuery } from "@Pages/index";

interface FilterObj {
  [fieldName: string]: {
    selected?: boolean;
    list?: string[];
    range?: number[];
  };
}

const useStyles = makeStyles({});

export default function SimpleDialog() {
  const { t: t_database } = useTranslation("database");
  const theme = useTheme();
  const classes = useStyles();
  const { panelSize, query, setQuery } = useContext(PanelsContext);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleQueryChange = (newValue: string) => {
    setQuery({ ...query, text: newValue } as FeatureQuery);
  };

  const handleFormChange = (newValue: FormValue) => {
    const newQuery: FeatureQuery = { ...query };

    console.log("nV", newValue);

    newQuery.deepRange = newValue.rating.range;
    newQuery.operation = newValue.operation.list;
    newQuery.priceRange = newValue.price.range;
    newQuery.card = newValue.acceptsCard.selected;
    newQuery.bed = newValue.beds.selected;
    newQuery.pBathrooms = newValue.individualBathrooms.selected;
    newQuery.barrierfree = newValue.barrierFree.selected;
    newQuery.onsen = newValue.hotsprings.selected;
    newQuery.original = newValue.isOriginal.selected;
    newQuery.structure = newValue.structure.list;
    newQuery.conPeriod = newValue.conYear.list;
    newQuery.storey = newValue.storeys.list;
    // newQuery.buildingType =

    setQuery(newQuery);
  };

  const handleReset = () => {
    setQuery({});
  };

  return (
    <PanelsContext.Consumer>
      {(value) => (
        <Panel
          title="検索"
          id="search"
          maxWidth={1000}
          buttons={[
            <Button key={0} variant="contained" onClick={handleReset}>
              リセット
            </Button>,
            <Button key={1} variant="contained" color="primary">
              検索
            </Button>,
          ]}
          padding={1}
        >
          {value ? (
            <React.Fragment>
              {mobile ? (
                <QueryInput
                  fullScreen={panelSize === "full"}
                  onChange={handleQueryChange}
                />
              ) : null}

              <FilterCards
                fullScreen={panelSize === "full"}
                // searchParams={paramStr}
                onChange={handleFormChange}
              />
            </React.Fragment>
          ) : null}
        </Panel>
      )}
    </PanelsContext.Consumer>
  );
}
