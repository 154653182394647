import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "next-i18next";
import { PanelsContext } from "../../../pages/index";
import Typography from "@material-ui/core/Typography";
import Image from "next/image";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import HotelOutlinedIcon from "@material-ui/icons/HotelOutlined";

import Dialog from "@Components/database/dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: "none",
    },
    dialogTitle: {
      color: theme.palette.primary.main,
      padding: 8,
      height: 48,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "move",
    },

    content: {
      padding: "0 !important",
      minWidth: 400,
      width: "100%",
    },
    overlay: {
      position: "absolute",
      justifyContent: "center",
      display: "flex",
      width: "100%",
      height: "100%",
    },
    title: {
      marginLeft: 8,
      textAlign: "center",
    },
    container: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    badge: {
      padding: 8,
      display: "flex",
    },
    badgeNumber: {
      padding: 4,
    },
  })
);

export interface User {
  name: string;
  iconImg: string;
  profileImg: string;
  message: string;
}

export default function UserDialog() {
  const classes = useStyles();
  const { selectedUser, setSelectedUser, setSelectedYado } =
    useContext(PanelsContext);

  const handleClose = () => {
    setSelectedUser(undefined);
  };

  const [dialogOpen, setDialogOpen] = useState(!!selectedUser);
  useEffect(() => {
    setDialogOpen(!!selectedUser);
    if (selectedUser) {
      setSelectedYado(undefined);
    }
  }, [selectedUser]);

  return (
    <PanelsContext.Consumer>
      {(value) => {
        const user =
          value.selectedUser ||
          ({
            name: "Loading...",
            iconImg: "",
            profileImg: "",
            message: "Loading...",
          } as User);
        return (
          <Dialog
            title={user.name}
            id="user"
            open={dialogOpen}
            onClose={handleClose}
            avatar={user.iconImg}
          >
            <div className={classes.content}>
              <div style={{ position: "relative", height: 140 }}>
                <Image
                  src={user.profileImg}
                  alt="Profile Image"
                  layout="fill"
                  objectFit="cover"
                />
                <div
                  style={{
                    color: "#fff",
                    backgroundColor: "rgba(0,0,0,0.4)",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    className={classes.overlay}
                    style={{ alignItems: "center" }}
                  >
                    <Typography variant="body1">{user.message}</Typography>
                  </div>

                  <div
                    className={classes.overlay}
                    style={{
                      alignItems: "end",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className={classes.badge}>
                      <PersonAddOutlinedIcon />
                      <Typography
                        variant="body2"
                        className={classes.badgeNumber}
                      >
                        16
                      </Typography>
                    </div>

                    <div className={classes.badge}>
                      <ThumbUpOutlinedIcon />
                      <Typography
                        variant="body2"
                        className={classes.badgeNumber}
                      >
                        24
                      </Typography>
                    </div>

                    <div className={classes.badge}>
                      <HotelOutlinedIcon />
                      <Typography
                        variant="body2"
                        className={classes.badgeNumber}
                      >
                        24
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ padding: 16 }}>
                <Typography variant="h6">アルバム</Typography>
                <Typography variant="h6">リスト</Typography>
                <Typography variant="h6">投稿</Typography>
              </div>
            </div>
          </Dialog>
        );
      }}
    </PanelsContext.Consumer>
  );
}
