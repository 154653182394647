import React, { useContext } from "react";
import { useTranslation } from "next-i18next";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import { getImageUrl } from "@Config/graphCMSInit";
import { PanelsContext } from "../../../pages/index";
import Panel from "../panel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: { position: "absolute", bottom: 80, right: 24 },
    date: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    root: {
      margin: theme.spacing(1),
      minWidth: 275,
      backgroundColor: theme.palette.background.paper,
    },
    postInfo: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
    },
    userInfo: {
      marginLeft: "auto!important" as "auto",
      display: "flex",
      alignItems: "center",
    },
    userName: {
      fontSize: 14,
      marginRight: theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  })
);

const posts = [
  {
    title: "素晴らしい温泉。",
    yadoName: "ときやど旅館",
    text: (
      <React.Fragment>
        <b>露天風呂</b>から見える景色が最高だった。
      </React.Fragment>
    ),
    score: 14,
    user: {
      name: "もんじょう",
      icon: getImageUrl("VtSQ0WmnQLOvZgeuDXrL", {
        width: 80,
        height: 80,
      }),
    },
  },
];

const width = 400;
const height = 140;
const data = {
  name: "もんじょう",
  iconImg: getImageUrl("VtSQ0WmnQLOvZgeuDXrL", {
    width: 80,
    height: 80,
  }),
  profileImg: getImageUrl("UqdHrT9Q6OLpISZ2t7zB", {
    width,
    height,
  }),
  message: "プロフィールメッセージ。自己紹介など。",
};

export default function CommentsPanel() {
  const { t: t_database } = useTranslation("database");
  const classes = useStyles();

  const { setSelectedUser } = useContext(PanelsContext);
  const handleClickOpen = () => {
    setSelectedUser(data);
  };

  return (
    <PanelsContext.Consumer>
      {(value) => (
        <Panel title="投稿" id="comments" padding={0}>
          <Fab className={classes.fab} color="primary" aria-label="write">
            <CreateOutlinedIcon />
          </Fab>

          <Typography
            className={classes.date}
            variant="body1"
            color="textSecondary"
          >
            訪問：2022年7月5日
          </Typography>
          {posts.map((item, i) => {
            return (
              <Card key={i} className={classes.root}>
                <CardContent>
                  <Typography variant="h5">{item.title}</Typography>
                  <Button>@{item.yadoName}</Button>
                  <Typography variant="body2" component="p">
                    {item.text}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton aria-label="upvote">
                    <ThumbUpOutlinedIcon />
                  </IconButton>
                  {item.score}
                  <IconButton aria-label="downvote">
                    <ThumbDownOutlinedIcon />
                  </IconButton>
                  <Button
                    className={classes.userInfo}
                    onClick={handleClickOpen}
                  >
                    <Typography
                      className={classes.userName}
                      color="textSecondary"
                    >
                      {item.user.name}
                    </Typography>
                    <Avatar
                      alt={item.user.name}
                      src={item.user.icon}
                      className={classes.avatar}
                    />
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </Panel>
      )}
    </PanelsContext.Consumer>
  );
}
