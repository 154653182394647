import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import Badge from "@material-ui/core/Badge";

import { PanelsContext, DialogEnum } from "@Pages/index";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import TuneOutlinedIcon from "@material-ui/icons/TuneOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.modal,
      position: "fixed",
      right: 0,
      bottom: 0,
      width: 400,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

export default function LabelBottomNavigation() {
  const classes = useStyles();
  const {
    imageCount,
    openPanel,
    setOpenPanel,
    setSelectedYado,
    setSelectedUser,
  } = useContext(PanelsContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setOpenPanel(newValue as DialogEnum);

    setSelectedYado(undefined);
    setSelectedUser(undefined);
  };

  return (
    <div className={classes.root}>
      <BottomNavigation onChange={handleChange} value={openPanel}>
        <BottomNavigationAction
          label="画像"
          value="images"
          icon={
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              max={99}
              badgeContent={imageCount}
              color="secondary"
            >
              <DashboardOutlinedIcon />
            </Badge>
          }
        />
        <BottomNavigationAction
          label="絞り込み"
          value="search"
          icon={<TuneOutlinedIcon />}
        />
        <BottomNavigationAction
          label="投稿"
          value="comments"
          icon={<RateReviewOutlinedIcon />}
        />
      </BottomNavigation>
    </div>
  );
}
