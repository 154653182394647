import React from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { TabData } from "@Components/database/info/tatemonoTabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      height: 120,
      width: 120,
    },
  })
);

interface CardProps {
  data: TabData;
}

export default function MediaControlCard(props: CardProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={props.data?.images[0] ? props.data?.images[0].src : undefined}
        title={props.data?.tatemono.name}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle1">
            {props.data?.tatemono.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`${props.data.tatemono.structure}・${props.data.tatemono.storeys}階`}
          </Typography>
          {props.data?.tatemono?.registration?.map((value, i) => {
            return (
              <Typography variant="body2" color="textSecondary" key={i}>
                {value}
              </Typography>
            );
          })}
        </CardContent>
      </div>
    </Card>
  );
}
