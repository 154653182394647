import React, { ReactNode, useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Brightness4OutlinedIcon from "@material-ui/icons/Brightness4Outlined";
import useDarkMode from "use-dark-mode";

import Drawer from "@Components/appBar/drawer";
import ThemeButton from "@Components/appBar/themeButton";
import AccountButton from "@Components/appBar/accountButton";
import QueryInput from "@Components/database/search/queryInput";
import { PanelsContext, FeatureQuery } from "@Pages/index";
import SearchModalDB from "@Components/appBar/searchModalDB";
import Logo from "@Components/common/logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: "calc(100% - 400px)",
      left: 0,
      padding: "0 8px",
      background: "none",
      // backdropFilter: "blur(12px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    toolbar: {
      minHeight: 48,
      padding: 0,
    },
    grow: {
      flexGrow: 1,
    },
    content: {},
  })
);

export interface AppBarProps {
  isSplashOpen: boolean;
  children?: ReactNode;
  onImagesButton?: () => void;
  imageCount?: number;
  onHelpButton?: () => void;
  onSearchButton?: () => void;
}

export default function PersistentDrawerLeft(props: AppBarProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const {  query, setQuery } = useContext(PanelsContext);
  const handleQueryChange = (newValue: string) => {
    setQuery({ ...query, text: newValue } as FeatureQuery);
  };

  const [themeColor, setThemeColor] = React.useState("light");
  const darkMode = useDarkMode(false, {
    onChange: () => {
      setThemeColor(darkMode.value ? "dark" : "light");
    },
  });

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          color: themeColor === "light" ? theme.palette.primary.main : "#fff",
          ...(props.isSplashOpen ? { width: "100%" } : {}),
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open-drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <Logo />

          <div className={classes.grow} />

          <SearchModalDB />

          <ThemeButton />

          <AccountButton />
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <main>{props.children}</main>
    </div>
  );
}
