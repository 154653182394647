import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LabelIcon from "@material-ui/icons/LabelOutlined";

import { client } from "../../../config/algoliaInit";
import { Query } from "../../../types/query";
import { onsenIcons, yadoIcons, tatemonoIcons } from "../../../lib/icons";
import { useTranslation } from "next-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: 0,
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
  })
);

interface ACOption {
  inputValue?: string;
  title: string;
  cat: string;
}

export interface QueryInputProps {
  help?: boolean;
  // query: string
  onChange: (value: string) => void;
  fullScreen?: boolean;
}

const filter = createFilterOptions<ACOption>();

const optionToString = (option: ACOption) => {
  if (typeof option === "string") {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.title;
};

const getSearchText = (optionArray: ACOption[]) => {
  const searchStr = optionArray.map((option) => {
    return optionToString(option);
  });

  return searchStr.join(" ");
};

const index = client.initIndex("query_type_ja");

export default function CenteredGrid(
  props: QueryInputProps
): React.ReactElement {
  const { t: t_database } = useTranslation("database");
  const classes = useStyles();
  const [options, setOptions] = React.useState<ACOption[]>([]);
  const [value, setValue] = React.useState<ACOption[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  let icons: { [index: string]: React.ReactElement } = {};

  icons[t_database("search.search_bar.categories.onsen_name")] =
    onsenIcons.onsen;
  icons[t_database("search.search_bar.categories.yado_name")] = yadoIcons.yado;
  icons[t_database("search.search_bar.categories.tatemono")] =
    tatemonoIcons.tatemono;
  icons[t_database("search.search_bar.categories.prefecture")] =
    yadoIcons.prefecture;
  icons[t_database("search.search_bar.categories.registration")] =
    tatemonoIcons.registration;
  icons[t_database("search.search_bar.categories.operation")] =
    yadoIcons.operation;
  icons[t_database("search.search_bar.categories.bed_type")] = yadoIcons.bed;
  icons[t_database("search.search_bar.categories.bathroom_type")] =
    yadoIcons.bathroom;
  icons[t_database("search.search_bar.categories.structure")] =
    tatemonoIcons.structure;
  icons[t_database("search.search_bar.categories.construction")] =
    tatemonoIcons.construction;
  icons[t_database("search.search_bar.categories.state")] = tatemonoIcons.state;
  icons[t_database("search.search_bar.categories.commnent")] =
    tatemonoIcons.commnent;
  icons[t_database("search.search_bar.categories.free_word")] = <LabelIcon />;

  const handleACChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    if (query === "") {
      setOptions([]);
    } else {
      setLoading(true);
      index
        .search(query, {
          hitsPerPage: 10,
        })
        .then((res: any) => {
          const listData: ACOption[] = [];
          const items = res.hits as Query[];

          items.forEach((item) => {
            listData.push({
              title: item.query,
              cat: item.type.ja,
            });
          });

          setOptions(listData);
          setLoading(false);
        });
    }
  };

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      justifyContent="center"
    >
      <Grid key="a" item xs={12} md={props.fullScreen ? 8 : 12}>
        <Autocomplete
          filterSelectedOptions
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.unshift({
                inputValue: params.inputValue,
                title: `"${params.inputValue}"`,
                cat: t_database("search.search_bar.categories.free_word"),
              });
            }

            return filtered;
          }}
          groupBy={(option: ACOption) => option.cat}
          getOptionLabel={(option: ACOption) => {
            return optionToString(option);
          }}
          inputValue={inputValue}
          loading={loading}
          loadingText={t_database("search.search_bar.loading")}
          multiple
          noOptionsText={t_database("search.search_bar.no_results")}
          onChange={(event, newValue: ACOption[]) => {
            props.onChange(getSearchText(newValue));
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          openOnFocus={true}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleACChange}
              variant="outlined"
              label={t_database("search.search_bar.label")}
              placeholder={t_database("search.search_bar.placeholder")}
              helperText={t_database("search.search_bar.helper_text")}
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingRight: 9,
                },
              }}
            />
          )}
          renderOption={(option) => {
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <div className={classes.icon}>{icons[option.cat]}</div>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    {option.title}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          value={value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                icon={icons[option.cat]}
                label={option.title}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Grid>
    </Grid>
  );
}
