import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles({
  image: {
    objectFit: "cover",
  },
});

const markerColor = "d74b22";

const mapConfig = {
  id: process.env.NEXT_PUBLIC_STATIC_MAPS_TOKEN_ID,
  api: process.env.NEXT_PUBLIC_STATIC_MAPS_TOKEN_KEY,
};

export interface MapImageProps {
  alt: string;
  height?: number;
  width?: number;
  address: string;
  name: string;
  radius?: number;
}

export const getMapURL = (address?: string, name?: string) => {
  return `https://www.google.com/maps/search/?api=1&query=${address} ${name}`;
};

export const getMapImage = (address: string) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&markers=color:0x${markerColor}|${address}&zoom=10&size=${480}x${480}&key=${
    mapConfig.api
  }&map_id=${mapConfig.id}`;
};

let rad = 0;

export default function ImgMediaCard(props: MapImageProps): React.ReactElement {
  const classes = useStyles();

  if (props.radius) {
    rad = props.radius;
  }

  return (
    <LazyLoadImage
      className={classes.image}
      effect="opacity"
      alt={props.alt}
      height={props.height || "100%"}
      width={props.width || "100%"}
      style={{ borderRadius: rad }}
      src={getMapImage(props.address)}
    />
  );
}
