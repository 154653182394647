import React, { useContext } from "react";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import SearchBarDB, { ACOption } from "@Components/appBar/searchBarDB";
import { PanelsContext, FeatureQuery } from "@Pages/index";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export default function SearchModalDB() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setOpenPanel, setPanelSize } = useContext(PanelsContext);

  // Mobile
  const handleClickOpen = () => {
    setOpenPanel("search");
    setPanelSize("full");
  };

  return (
    <PanelsContext.Consumer>
      {(value) =>
        value ? (
          mobile ? (
            <IconButton color="inherit" onClick={handleClickOpen}>
              <SearchIcon />
            </IconButton>
          ) : (
            <SearchBarDB />
          )
        ) : null
      }
    </PanelsContext.Consumer>
  );
}
