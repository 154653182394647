import { NextRouter } from "next/router";

export const addQueryParam = (router: NextRouter, paramObject: object) => {
  router.push({ query: paramObject as any }, undefined, {
    shallow: true,
  });
};

export const removeQueryParam = (router: NextRouter, key: string) => {
  const { pathname, query } = router;
  const searchParamObject = new URLSearchParams(query as any);
  searchParamObject.delete(key);
  router.push({ pathname, query: searchParamObject.toString() }, undefined, {
    shallow: true,
  });
};
