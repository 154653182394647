import React, { ReactNode, ReactElement, useContext, useRef } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import useDarkMode from "use-dark-mode";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  alpha,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Popper from "@material-ui/core/Popper";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { client } from "@Config/algoliaInit";
import { Query } from "@Types/query";
import { onsenIcons, yadoIcons, tatemonoIcons } from "@Lib/icons";
import { PanelsContext, FeatureQuery } from "@Pages/index";

import LabelIcon from "@material-ui/icons/LabelOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      backgroundColor:
        theme.palette.type === "dark"
          ? alpha("#fff", 0.2)
          : alpha(theme.palette.primary.light, 0.2),
      "&:hover": {
        backgroundColor:
          theme.palette.type === "dark"
            ? alpha("#fff", 0.1)
            : alpha(theme.palette.primary.light, 0.1),
      },
      width: "auto",
      maxWidth: "calc(100% - 300px)",

      position: "relative",
      borderRadius: theme.shape.borderRadius,
      margin: `0 ${theme.spacing(1)}px`,

      [theme.breakpoints.down("sm")]: {
        backgroundColor: alpha("#FFF", 0.1),
        "&:hover": {
          backgroundColor: alpha("#FFF", 0.2),
        },
        width: "100%",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 152,
        "&:focus": {
          width: 304,
        },
      },
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    list: {
      // height: 96,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    // Desktop
    popper: {
      borderRadius: 4,
      width: 360,
      zIndex: theme.zIndex.appBar + 1,
      top: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export interface ACOption {
  id: string;
  inputValue?: string;
  title: string;
  cat: string;
  icon: ReactElement;
}

interface SearchBarProps {}

const optionToString = (option: ACOption) => {
  if (typeof option === "string") {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.title;
};
const getSearchText = (optionArray: ACOption[]) => {
  const searchStr = optionArray.map((option) => {
    return optionToString(option);
  });

  return searchStr.join(" ");
};

export default function SearchBarDB(props: SearchBarProps) {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const darkMode = useDarkMode();
  // const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t: t_database } = useTranslation("database");

  const { setOpenPanel, query, setQuery } =
    useContext(PanelsContext);
  const refreshSearchQuery = (options: ACOption[]) => {
    const textQuery = getSearchText(options);
    setQuery({ ...query, text: textQuery } as FeatureQuery);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [options, setOptions] = React.useState<ACOption[]>([]);
  const [value, setValue] = React.useState<ACOption[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const setRefValue = (str: string) => {
    if (inputRef && inputRef.current) inputRef.current.value = str;
  };

  const addQuery = (option: ACOption) => {
    const curr = value;
    curr.push(option);

    setValue(curr);
    refreshSearchQuery(curr);
    setOptions([]);

    setRefValue("");
    inputRef.current?.focus();
  };

  const keyPress = (e: any) => {
    if (e.keyCode == 13) {
      if (options && options.length) {
        const last = options.at(-1) as ACOption;
        addQuery(last);
      }
    } else if (e.keyCode == 8 && !inputRef.current?.value) {
      const curr = value;
      curr.pop();

      setValue(curr);
      refreshSearchQuery(curr);
    }
  };

  /* let icons: { [index: string]: React.ReactElement } = {};

  icons[t_database("search.search_bar.categories.onsen_name")] =
    onsenIcons.onsen;
  icons[t_database("search.search_bar.categories.yado_name")] = yadoIcons.yado;
  icons[t_database("search.search_bar.categories.tatemono")] =
    tatemonoIcons.tatemono;
  icons[t_database("search.search_bar.categories.prefecture")] =
    yadoIcons.prefecture;
  icons[t_database("search.search_bar.categories.registration")] =
    tatemonoIcons.registration;
  icons[t_database("search.search_bar.categories.operation")] =
    yadoIcons.operation;
  icons[t_database("search.search_bar.categories.bed_type")] = yadoIcons.bed;
  icons[t_database("search.search_bar.categories.bathroom_type")] =
    yadoIcons.bathroom;
  icons[t_database("search.search_bar.categories.structure")] =
    tatemonoIcons.structure;
  icons[t_database("search.search_bar.categories.construction")] =
    tatemonoIcons.construction;
  icons[t_database("search.search_bar.categories.state")] = tatemonoIcons.state;
  icons[t_database("search.search_bar.categories.commnent")] =
    tatemonoIcons.commnent;
  icons[t_database("search.search_bar.categories.free_word")] = <LabelIcon />; */

  const handleACChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentText = event.target.value;
    setInputValue(currentText);
    console.log("handleACChange", currentText);

    setOpenPanel("search");

    if (currentText) {
      const target = event.currentTarget.parentElement?.parentElement;
      if (target) setAnchorEl(target);

      const data = [
        {
          id: `freeword-${currentText}`,
          inputValue: currentText,
          cat: "Free Word",
          title: currentText,
          icon: <LabelIcon />,
        },
      ];
      setOptions(data);
    } else {
      setAnchorEl(null);
      setOptions([]);
    }

    /* if (query === "") {
      setOptions([]);
    } else {
      setLoading(true);
       index
        .search(query, {
          hitsPerPage: 10,
        })
        .then((res: any) => {
          const listData: ACOption[] = [];
          const items = res.hits as Query[];

          items.forEach((item) => {
            listData.push({
              title: item.query,
              cat: item.type.ja,
            });
          });

          console.log(listData);
          setOptions(listData);
          setLoading(false);
        }); 
    } */
  };

  const handleOptionClick = (option: ACOption) => {
    console.log("click");
    addQuery(option);
  };

  const handleDelete = (option: ACOption) => {
    const newArr = value.filter((item) => {
      return option.id !== item.id;
    });

    setValue(newArr);
    refreshSearchQuery(newArr);
    setRefValue("");
    inputRef.current?.focus();
  };

  const handleReset = () => {
    setValue([]);
    refreshSearchQuery([]);

    setInputValue("");

    setAnchorEl(null);
  };

  const handleBlur = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          inputRef={inputRef}
          startAdornment={value.map((item, i) => (
            <Chip
              key={i}
              tabIndex={-1}
              label={item.title}
              icon={item.icon}
              // className={classes.chip}
              onDelete={() => handleDelete(item)}
            />
          ))}
          endAdornment={
            inputValue ? (
              <IconButton size="small" onClick={handleReset}>
                <CloseOutlinedIcon />
              </IconButton>
            ) : null
          }
          autoFocus={true}
          placeholder="検索…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={handleACChange}
          onKeyDown={keyPress}
          // onFocus={handleFocus}
          // onBlur={handleBlur}
        />
      </div>

      <Popper
        className={classes.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <List dense={true}>
          {options.length !== 0 ? (
            options.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <ListItem
                    button
                    className={classes.list}
                    onClick={() => handleOptionClick(item)}
                  >
                    <ListItemAvatar>
                      {React.cloneElement(item.icon, {
                        style: { color: theme.palette.text.primary },
                      })}
                    </ListItemAvatar>

                    <ListItemText primary={item.title} secondary={item.cat} />
                  </ListItem>
                  {i + 1 < options.length ? <Divider /> : null}
                </React.Fragment>
              );
            })
          ) : (
            <ListItem className={classes.list}>
              <ListItemText
                primary="候補なし"
                secondary="ほかのキーワードをお試しください"
              />
            </ListItem>
          )}
        </List>
      </Popper>
    </React.Fragment>
  );
}
