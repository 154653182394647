import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

import Contents from "./heroContents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { height: "100vh", position: "relative" },
    video: {
      right: 0,
      bottom: 0,
      width: "100%",
      overflowX: "hidden",
      height: "100vh",
      objectFit: "cover",
    },
    overlay: {
      display: "flex",
      position: "absolute",
      backgroundBlendMode: "multiply",
      top: 0,
      height: "100%",
      width: "100%",
    },
  })
);

export default function Home(props: any) {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      {props.video ? (
        <video autoPlay muted loop className={classes.video}>
          <source src={props.video} type="video/mp4" />
        </video>
      ) : props.image ? (
        <Image
          alt="image"
          objectFit="cover"
          layout="fill"
          src={props.image}
          quality={75}
          style={{ filter: "saturate(80%)" }}
        />
      ) : null}

      <div
        className={classes.overlay}
        style={{
          backgroundColor: props.tint || "unset",
        }}
      >
        <Contents {...props} />
      </div>

      <Divider />
    </div>
  );
}
