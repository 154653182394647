import React from 'react'
import HotTubIcon from '@material-ui/icons/HotTubOutlined'

import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import BusinessIcon from '@material-ui/icons/BusinessOutlined'
import KingBedIcon from '@material-ui/icons/KingBedOutlined'
import WcIcon from '@material-ui/icons/WcOutlined'
import PaymentIcon from '@material-ui/icons/PaymentOutlined'
import AccessibleIcon from '@material-ui/icons/AccessibleOutlined'

import HeightIcon from '@material-ui/icons/HeightOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import CommentIcon from '@material-ui/icons/CommentOutlined'
import BuildIcon from '@material-ui/icons/BuildOutlined'
import StarBorderIcon from '@material-ui/icons/StarBorderOutlined'
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined'
import HotelIcon from '@material-ui/icons/HotelOutlined'

const onsenIcons = {
	onsen: <HotTubIcon />,
}

const yadoIcons = {
	yado: <BusinessIcon />,
	onsen: <HotTubIcon />,
	rating: <ThumbUpIcon />,
	url: <LinkIcon />,
	phoneNumber: <PhoneIcon />,
	address: <PlaceIcon />,
	prefecture: <PlaceIcon />,
	operation: <BusinessIcon />,
	bed: <KingBedIcon />,
	bathroom: <WcIcon />,
	payment: <PaymentIcon />,
	barrierFree: <AccessibleIcon />,
}

const tatemonoIcons = {
	tatemono: <HotelIcon />,
	storeys: <HeightIcon />,
	price: <LocalOfferIcon />,
	commnent: <CommentIcon />,
	review: <CommentIcon />,
	structure: <BuildIcon />,
	registration: <StarBorderIcon />,
	construction: <CalendarTodayIcon />,
	state: <BuildIcon />,
	renovation: <BuildIcon />,
	accommodation: <HotelIcon />,
	roomCount: <HotelIcon />,
}

export { onsenIcons, yadoIcons, tatemonoIcons }
