import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mapboxgl from "mapbox-gl";
import { Feature, FeatureCollection } from "geojson";
import useDarkMode from "use-dark-mode";
const MapboxLanguage = require("@mapbox/mapbox-gl-language");

import { PanelSize } from "@Components/database/panel";
import SearchPanel from "@Components/database/search/searchPanel";
import ImagesPanel from "@Components/database/images/imagesPanel";
import CommentsPanel from "@Components/database/comments/commentsPanel";
import Loading from "@Components/database/loading";
import AppBar, { AppBarProps } from "@Components/appBar/appBarDB";
import geoJA from "@Data/geo_ja.json";
import geoEN from "@Data/geo_en.json";
import { YadoUni, TatemonoUni, YadoGeo } from "@Types/database";
import { db } from "@Config/firebaseInit";
import SEO from "@Components/common/seo";
import BottomNav from "@Components/database/bottomNav";
import Splash from "@Components/database/splash";
import InfoDialog from "@Components/database/info/infoDialog";
import Pins from "@Components/database/pins";
import { addQueryParam, removeQueryParam } from "@Lib/nextjs";
import UserDialog, { User } from "@Components/database/user/userDialog";

mapboxgl.accessToken =
  process.env.NODE_ENV === "production"
    ? `${process.env.NEXT_PUBLIC_MAPBOX_GL_ACCESS_TOKEN}`
    : `${process.env.NEXT_PUBLIC_MAPBOX_GL_LOCAL_TOKEN}`;

const disableMapFocus = true;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      position: "absolute",
    },
    map: {
      height: "100%",
      width: `calc(100vw - 400px)`,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 106px)",
        width: "100%",
      },
    },
    popup: {
      color: "rgba(0, 0, 0, 0.87)",
    },
    badge: {},
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

export type DialogEnum = "images" | "search" | "comments";

export const getStaticProps = async ({ locale }: any) => {
  const tatemonoQuerySnapshot = await db.collection("tatemono_" + locale).get();
  const tatemonos = tatemonoQuerySnapshot.docs.map((doc) => {
    return doc.data() as TatemonoUni[];
  });
  const yadoQuerySnapshot = await db.collection("yado_" + locale).get();
  const yados = yadoQuerySnapshot.docs.map((doc) => {
    return doc.data() as YadoUni[];
  });

  return {
    props: {
      yados,
      tatemonos,
      ...(await serverSideTranslations(locale, ["database", "common"])),
    },
  };
};

interface DatabaseProps {
  yados: YadoUni[];
  tatemonos: TatemonoUni[];
}

export interface FeatureQuery {
  text?: string;
  deepRange?: number[];
  operation?: string[];
  priceRange?: number[];
  card?: boolean;
  bed?: boolean;
  pBathrooms?: boolean;
  barrierfree?: boolean;
  onsen?: boolean;
  original?: boolean;
  structure?: string[];
  conPeriod?: string[];
  storey?: string[];
  // buildingType?: string[];
}

interface PanelContextData {
  openPanel: DialogEnum;
  setOpenPanel: React.Dispatch<React.SetStateAction<DialogEnum>>;
  panelSize: PanelSize;
  setPanelSize: React.Dispatch<React.SetStateAction<PanelSize>>;
  selectedYado: YadoUni | undefined | null;
  setSelectedYado: any;
  visibleYados: YadoUni[];
  setVisibleYados: React.Dispatch<React.SetStateAction<YadoUni[]>>;
  imageCount: number;
  setImageCount: React.Dispatch<React.SetStateAction<number>>;
  allYados: YadoUni[];
  setAllYados: React.Dispatch<React.SetStateAction<YadoUni[]>>;
  allTatemonos: TatemonoUni[];
  setAllTatemonos: React.Dispatch<React.SetStateAction<TatemonoUni[]>>;
  query: FeatureQuery;
  setQuery: React.Dispatch<React.SetStateAction<FeatureQuery>>;
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: User | undefined;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const PanelsContext = React.createContext<PanelContextData>(null!);

export default function Home({ yados, tatemonos }: DatabaseProps) {
  const { t: t_common } = useTranslation("common");
  const { t: t_database } = useTranslation("database");
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Theme Color
  const [background, setBackGround] = React.useState("#ececec");
  const darkMode = useDarkMode(false, {
    onChange: () => {
      setBackGround(darkMode.value ? "#343332" : "#ececec");
    },
  });

  // Map Constants
  const moveVariants = {
    moving: { opacity: 0.5 },
    stopped: { opacity: 1 },
  };
  const moveTimeout = 500;
  const [mapMoving, setMapMoving] = useState(false);
  const map = useRef<mapboxgl.Map>(null!);
  const popup = useRef<mapboxgl.Popup>(null!);
  const mapCenter = [136, 37.5];
  const margin = 10;
  const dot_min = 5.6;
  const dot_increment = 0.8;
  const language = new MapboxLanguage({
    defaultLanguage: router.locale as string,
  });
  const geoYado =
    router.locale === "ja"
      ? (geoJA as FeatureCollection)
      : (geoEN as FeatureCollection);

  const colors: { [id: string]: string } = {
    ryokan: "#fbb03b",
    hotel: "#54a86a",
    minshuku: "#e55e5e",
    guestHouse: "#3bb2d0",
    other: "#ccc",
  };

  const DotColorMatch =
    router.locale === "ja"
      ? [
          "match",
          ["get", "operation"],
          "旅館",
          colors.ryokan,
          "ホテル",
          colors.hotel,
          "民宿",
          colors.minshuku,
          "ゲストハウス",
          colors.guestHouse,
          colors.other,
        ]
      : [
          "match",
          ["get", "operation"],
          "旅館",
          "#fbb03b",
          "ホテル",
          "#54a86a",
          "民宿",
          "#e55e5e",
          "ゲストハウス",
          "#3bb2d0",
          "#ccc",
        ];

  // Panels
  const yadoMapObject: { [id: number]: YadoUni } = {};
  yados.forEach((yado) => {
    yadoMapObject[yado.id] = yado;
  });
  const yadoGeoObject: { [id: number]: Feature } = {};
  geoYado.features.forEach((feature) => {
    if (feature.properties?.id) yadoGeoObject[feature.properties.id] = feature;
  });

  const initialList = yados;
  const [allYados, setAllYados] = useState(initialList);
  const [allTatemonos, setAllTatemonos] = useState<TatemonoUni[]>(tatemonos);
  const [openPanel, setOpenPanel] = useState<DialogEnum>("images");
  const [panelSize, setPanelSize] = useState<PanelSize>("mid");
  const [query, setQuery] = useState<FeatureQuery>({});
  const [selectedImage, setSelectedImage] = useState("");
  const handleOpenPanel = (id: DialogEnum) => {
    setOpenPanel(id);
  };

  // Progress Status
  const progress = useRef(0);
  const [loadProgress, setLoadProgress] = React.useState(0);
  const updateProgress = (msg: string): void => {
    progress.current += 1;
    setLoadProgress(progress.current);
  };

  // Splash Controllers
  const [splashOpen, setSplashOpen] = useState(true);
  const handleCloseSplash = () => {
    setSplashOpen(false);
  };

  // Selected Item
  // null for initial state, undefined for deselecting
  const [selectedYado, setSelectedYado] = useState<YadoUni | undefined | null>(
    null
  );

  useEffect(() => {
    const yadoId = router.query.yado
      ? parseInt(router.query.yado as string)
      : null;

    if (yadoId) {
      const yado = yadoMapObject[yadoId];

      if (map.current.loaded()) {
        setSelectedYado(yado);
      } else {
        map.current.once("load", () => {
          setSelectedYado(yado);
        });
      }
    }
  }, [router.query.yado]);

  // Visible Item
  const [visibleYados, setVisibleYados] = useState(initialList);
  const prevList = useRef<YadoUni[]>([]);
  const currentList = useRef<YadoUni[]>([]);
  const [imageCount, setImageCount] = useState(0);
  const handleVisible = () => {
    prevList.current = currentList.current;

    const features = map.current.queryRenderedFeatures(undefined, {
      layers: ["yado-layer"],
    });

    const visibleNew = features.map((v) => {
      return v.properties as YadoUni;
    });

    currentList.current = visibleNew;

    const match = prevList.current.filter(
      ({ id: id1 }) => !currentList.current.some(({ id: id2 }) => id2 === id1)
    );

    let diff = true;
    if (prevList.current.length !== currentList.current.length) {
      diff = true;
    } else {
      diff = match.length !== 0;
    }

    if (diff) setVisibleYados(visibleNew);
  };

  const handleMapClick = (e: any) => {
    const yadoId = (e.features[0].properties as YadoGeo).id;

    const selYado = allYados.filter((yado) => {
      return yado.id === yadoId;
    })[0];

    console.log(yadoId, selYado);

    setSelectedYado(selYado);
  };

  useEffect(() => {
    if (selectedYado) {
      const yadoId = selectedYado.id;
      if (router.isReady)
        addQueryParam(router, { ...router.query, yado: yadoId });

      const geoData = yadoGeoObject[yadoId] as any;
      const coords = geoData.geometry.coordinates as mapboxgl.LngLatLike;

      if (mobile && !disableMapFocus) {
        map.current.flyTo({
          center: coords,
          offset: openPanel ? [0, -120] : [0, 0],
        });
      }

      let opEng = "other";
      switch (selectedYado.operation) {
        case "旅館":
          opEng = "ryokan";
          break;
        case "ホテル":
          opEng = "hotel";
          break;
        case "民宿":
          opEng = "minshuku";
          break;
        case "ゲストハウス":
          opEng = "guestHouse";
          break;
        default:
          opEng = "other";
      }

      if (!popup.current) {
        popup.current = new mapboxgl.Popup({ className: classes.popup });
      }

      popup.current
        .setLngLat(coords)
        .setHTML(
          `
            <span style="height:12px; width:12px; background-color:${colors[opEng]}; border-radius:50%; display:inline-block;"></span>
            <span style="color:${colors[opEng]}">${selectedYado.operation} </span>
            <div>${selectedYado.name}</div>
            <div>${selectedYado.nameAlt}</div>
          `
        )
        .addTo(map.current);
    } else if (selectedYado !== null) {
      if (router.isReady) removeQueryParam(router, "yado");
      if (!selectedYado && popup.current) popup.current.remove();
    }
  }, [router.isReady, selectedYado?.id]);

  useEffect(() => {
    updateProgress("useEffect");

    map.current = new mapboxgl.Map({
      attributionControl: false,
      container: "my-map",
      style: darkMode.value
        ? "mapbox://styles/mapbox/dark-v10"
        : "mapbox://styles/mapbox/light-v10",
      center: mapCenter as any,
      maxBounds: [
        [122.9325 - margin, 20.425246 - margin], // Southwest coordinates
        [153.986667 + margin, 45.557228 + margin], // Northeast coordinates
      ],
      zoom: 4,
    });

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
    map.current.addControl(language);

    map.current.on("load", () => {
      updateProgress("map_load");

      map.current.addSource("yado", {
        type: "geojson",
        data: geoYado,
      });

      map.current.addLayer({
        id: "yado-layer",
        type: "circle",
        source: "yado",
        paint: {
          "circle-radius": [
            "match",
            ["get", "rating"],
            1,
            dot_min + 1 * dot_increment,
            2,
            dot_min + 2 * dot_increment,
            3,
            dot_min + 3 * dot_increment,
            4,
            dot_min + 4 * dot_increment,
            5,
            dot_min + 5 * dot_increment,
            dot_min,
          ],
          "circle-color": DotColorMatch as mapboxgl.Expression,
          "circle-opacity": [
            "interpolate",
            ["linear"],
            ["get", "rating"],
            0,
            0.65,
            5,
            1,
          ],
        },
      });
    });

    map.current.once("sourcedata", (e) => {
      updateProgress("map_sourcedata");
    });

    map.current.once("idle", (e) => {
      updateProgress("map_idle");

      handleVisible();
    });

    map.current.on("click", "yado-layer", handleMapClick);

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on("mouseenter", "yado-layer", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.current.on("mouseleave", "yado-layer", () => {
      map.current.getCanvas().style.cursor = "";
    });

    map.current.on("movestart", () => {
      setTimeout(() => {
        if (map.current.isMoving()) {
          setMapMoving(true);
        }
      }, moveTimeout);
    });

    map.current.on("moveend", () => {
      handleVisible();

      setTimeout(() => {
        if (!map.current.isMoving()) {
          setMapMoving(false);
        }
      }, moveTimeout);
    });

    map.current.addControl(
      new mapboxgl.AttributionControl({
        customAttribution: "Map design by TokiYado.",
        compact: true,
      })
    );

    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true,
      }),
      "bottom-right"
    );

    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "bottom-right"
    );
  }, []);

  const sample: YadoGeo = geoYado.features[0].properties as YadoGeo;

  useEffect(() => {
    const mapStyle = router.query.style;
    if (mapStyle) {
      map.current.setStyle(mapStyle as string);
    }
  }, [router.query.style]);

  useEffect(() => {
    if (map.current.loaded() && map.current.isStyleLoaded()) {
      map.current.setFilter("yado-layer");
      const filters: any = [];

      if (query.text) {
        const sub: any = [];

        query.text.split(" ").forEach((value) => {
          sub.push(["in", value, ["get", "Fquery"]]);
        });

        if (sub.length) filters.push(["any", ...sub]);
      }

      if (query.deepRange && query.deepRange.length)
        filters.push([
          "all",
          ["<=", query.deepRange[0], ["get", "rating"]],
          [">=", query.deepRange[1], ["get", "rating"]],
        ]);

      if (query.operation) {
        const sub: any = [];

        query.operation.forEach((value) => {
          if (value === "その他") {
            sub.push([
              "all",
              ["!", ["in", "旅館", ["get", "operation"]]],
              ["!", ["in", "ホテル", ["get", "operation"]]],
            ]);
          } else {
            sub.push(["in", value, ["get", "operation"]]);
          }
        });

        if (sub.length) filters.push(["any", ...sub]);
      }

      if (query.priceRange && query.priceRange.length) {
        filters.push([
          "all",
          ["<=", query.priceRange[0], ["get", "FpriceMax"]],
          [">=", query.priceRange[1], ["get", "FpriceMin"]],
        ]);
      }

      if (query.card) filters.push(["in", "カード", ["get", "payment"]]);

      if (query.bed) filters.push(["in", "ベッド", ["get", "bed"]]);

      if (query.pBathrooms) filters.push(["in", "個別", ["get", "bathroom"]]);

      if (query.barrierfree)
        filters.push(["in", "対応", ["get", "barrierFree"]]);

      if (query.onsen) filters.push(["has", "onsenName"]);

      if (query.original) filters.push(["get", "Foriginal"]);

      if (query.structure) {
        const sub: any = [];

        query.structure.forEach((structure) => {
          if (structure === "特殊造") {
            sub.push([
              "all",
              ["!", ["in", "RC", ["get", "FbuildingType"]]],
              ["!", ["in", "木", ["get", "FbuildingType"]]],
              ["!", ["in", "土蔵", ["get", "FbuildingType"]]],
            ]);
          } else {
            sub.push(["in", structure, ["get", "FbuildingType"]]);
          }
        });

        if (sub.length) filters.push(["any", ...sub]);
      }

      if (query.storey) {
        const sub: any = [];

        query.storey.forEach((storey) => {
          sub.push(["in", storey.charAt(0) + "階", ["get", "FbuildingType"]]);
        });

        if (sub.length) filters.push(["any", ...sub]);
      }

      if (query.structure && query.storey) {
        const structureArr = query.structure;
        const storeyArr = query.storey;

        const sub: any = [];

        storeyArr.forEach((storey) => {
          structureArr.forEach((structure) => {
            if (structure === "特殊造") {
              sub.push([
                "all",
                ["!", ["in", "RC", ["get", "FbuildingType"]]],
                ["!", ["in", "木", ["get", "FbuildingType"]]],
                ["!", ["in", "土蔵", ["get", "FbuildingType"]]],
                ["in", storey + "階", ["get", "FbuildingType"]],
              ]);
            } else {
              sub.push([
                "in",
                structure + storey.charAt(0) + "階",
                ["get", "FbuildingType"],
              ]);
            }
          });
        });

        console.log("s", sub);
        if (sub.length) filters.push(["any", ...sub]);
      }

      if (query.conPeriod) {
        const sub: any = [];

        query.conPeriod.forEach((period) => {
          sub.push(["in", period, ["get", "FconPeriod"]]);
        });

        if (sub.length) filters.push(["any", ...sub]);
      }

      console.log("f", filters, "q", query);
      map.current.setFilter("yado-layer", ["all", ...filters]);
    }
  }, [query, sample]);

  // User
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  return (
    <React.Fragment>
      <SEO
        pageTitle={selectedYado ? `${selectedYado.name}` : t_common("title")}
        pageImg={selectedImage}
      >
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.css"
          rel="stylesheet"
        />
      </SEO>

      <Loading progress={loadProgress} max={2} />

      <Splash onEnd={handleCloseSplash} />

      <PanelsContext.Provider
        value={{
          openPanel,
          setOpenPanel,
          panelSize,
          setPanelSize,
          selectedYado,
          setSelectedYado,
          visibleYados,
          setVisibleYados,
          imageCount,
          setImageCount,
          allYados,
          setAllYados,
          allTatemonos,
          setAllTatemonos,
          query,
          setQuery,
          selectedImage,
          setSelectedImage,
          selectedUser,
          setSelectedUser,
        }}
      >
        <AppBar
          isSplashOpen={splashOpen}
          imageCount={imageCount}
          onImagesButton={() => handleOpenPanel("images")}
          onSearchButton={() => handleOpenPanel("search")}
        >
          <div
            className={classes.container}
            style={{
              backgroundColor: background,
            }}
          >
            <div id="my-map" className={classes.map} />
          </div>
        </AppBar>

        {mobile ? (
          <motion.div
            variants={moveVariants}
            animate={mapMoving ? "moving" : "stopped"}
          >
            <ImagesPanel />
            <CommentsPanel />
            <SearchPanel />

            <InfoDialog />
            <UserDialog />
            <Pins />
          </motion.div>
        ) : (
          <React.Fragment>
            <ImagesPanel />
            <CommentsPanel />
            <SearchPanel />

            <motion.div
              variants={moveVariants}
              animate={mapMoving ? "moving" : "stopped"}
            >
              <InfoDialog />
              <UserDialog />
              <Pins />
            </motion.div>
          </React.Fragment>
        )}

        <BottomNav />
      </PanelsContext.Provider>
    </React.Fragment>
  );
}
