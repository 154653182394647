import React, { useContext, useEffect, useState, useRef } from "react";
import Image from "next/image";
import { AnimatePresence } from "framer-motion";
import useDarkMode from "use-dark-mode";
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import FullscreenOutlinedIcon from "@material-ui/icons/FullscreenOutlined";

import { PanelsContext } from "@Pages/index";
import CustomPaperMotionDiv, {
  getMidY,
  findClosestTarget,
  DragTarget,
} from "@Components/motion/paper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragBoundary: {
      pointerEvents: "none",
      position: "absolute",
      height: "100%",
      width: "100%",
    },
    root: {
      flexGrow: 1,
    },
    title: {
      padding: 0,
      color: theme.palette.primary.main,
    },
    navBar: {
      position: "absolute",
      top: 3,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    titleContainer: {
      marginLeft: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      lineHeight: "48px",
      height: 48,
      textAlign: "center",
      bottom: 0,
    },
    titleText: {
      width: "calc(100% - 48px)",
      textAlign: "start",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    closeButton: {
      display: "flex",
      alignSelf: "center",
    },
    paper: {
      height: "100%",
      zIndex: theme.zIndex.appBar + 1,
      backgroundColor: theme.palette.background.default,
      width: 400,
      right: 0,
      bottom: 0,
      position: "fixed",
      pointerEvents: "all",
      borderRadius: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 8,
        width: "100%",
      },
    },
    content: {
      height: "calc(100% - 60px)",
      overflowWrap: "break-word",
      [theme.breakpoints.up("sm")]: {
        height: "calc(100% - 104px)",
      },
    },
    dialogTitleContainer: {
      color: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-between",
    },
    dialogCloseButton: {
      margin: theme.spacing(1),
    },
    dialogContent: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  })
);

export interface PanelBaseProps {
  children?: React.ReactNode;
  title: string;
  id: string;
  maxWidth?: number | string;
  buttons?: React.ReactNode[];
  padding?: number;
}

export type PanelSize = "mini" | "mid" | "full";

export default function SimpleDialog(props: PanelBaseProps) {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedYado, setSelectedYado, panelSize, setPanelSize, openPanel } =
    useContext(PanelsContext);
  const darkMode = useDarkMode();

  const [y, setY] = useState(getMidY());
  const panelUp = () => {
    switch (panelSize) {
      case "mid":
        setPanelSize("full");
        return;
      case "mini":
        setPanelSize("mid");
        return;
    }
  };
  const panelDown = () => {
    switch (panelSize) {
      case "full":
        setPanelSize("mid");
        return;
      case "mid":
        setPanelSize("mini");
        return;
    }
  };
  const panelFullScreen = () => {
    setPanelSize("full");
  };
  useEffect(() => {
    if (panelSize === "full") {
      setPanelSize("mid");
    }
  }, [selectedYado]);

  useEffect(() => {
    setPanelSize("mid");
  }, [openPanel]);

  useEffect(() => {
    switch (panelSize) {
      case "full":
        setSelectedYado(undefined);
        setY(0);
        break;
      case "mid":
        setY(getMidY());
        break;
      case "mini":
        setY(window.innerHeight - 105);
        break;
    }
  }, [panelSize]);

  const constraintsRef = useRef(null);
  const handleDragEnd = (event: any, info: any) => {
    if (typeof window !== "undefined") {
      const lastY = info.point.y as number;

      const targets = [
        { size: "full", y: 0 },
        { size: "mid", y: getMidY() },
        { size: "mini", y: window.innerHeight - 105 },
      ];
      const closest = findClosestTarget(lastY, targets as DragTarget[]);

      setY(closest.y);
      setPanelSize(closest.size as PanelSize);
    }
  };

  return (
    <PanelsContext.Consumer>
      {(value) => (
        <React.Fragment>
          <div ref={constraintsRef} className={classes.dragBoundary}></div>

          <AnimatePresence exitBeforeEnter>
            {value.openPanel === props.id ? (
              <CustomPaperMotionDiv
                className={classes.paper}
                drag={mobile ? "y" : false}
                dragElastic={0.1}
                dragConstraints={mobile ? { top: 0 } : constraintsRef}
                dragMomentum={false}
                onDragEnd={mobile ? (handleDragEnd as any) : undefined}
                initial={{ y: mobile ? y : undefined, opacity: 0 }}
                animate={{ y: mobile ? y : undefined, opacity: 1 }}
                exit={{ y: mobile ? y : undefined, opacity: 0 }}
              >
                <DialogTitle className={classes.title}>
                  {mobile ? (
                    <div className={classes.navBar}>
                      <Image
                        src={`/nav-bar-${
                          darkMode.value ? "dark" : "light"
                        }.svg`}
                        alt="Nav Bar"
                        width={48}
                        height={6}
                      />
                    </div>
                  ) : null}

                  <div className={classes.titleContainer}>
                    <div
                      className={classes.titleText}
                      onClick={mobile ? panelUp : undefined}
                    >
                      {props.title}
                    </div>

                    <div className={classes.closeButton}>
                      {!mobile && value.openPanel !== "search" ? (
                        <IconButton
                          onClick={panelFullScreen}
                          color="inherit"
                          aria-label="expand panel"
                          component="span"
                        >
                          <FullscreenOutlinedIcon />
                        </IconButton>
                      ) : null}

                      {mobile && panelSize !== "mini" && !value.selectedYado ? (
                        <IconButton
                          onClick={panelDown}
                          color="inherit"
                          aria-label="close panel"
                        >
                          <KeyboardArrowDownOutlinedIcon />
                        </IconButton>
                      ) : null}
                    </div>
                  </div>
                </DialogTitle>

                <DialogContent
                  dividers
                  className={classes.content}
                  style={{
                    padding: props.padding ? props.padding * 8 : 0,
                  }}
                >
                  {props.children}
                </DialogContent>

                <Divider />
              </CustomPaperMotionDiv>
            ) : null}
          </AnimatePresence>

          {mobile ? null : (
            <Dialog
              fullScreen
              open={value.openPanel === props.id && panelSize === "full"}
              onClose={panelDown}
              aria-labelledby="responsive-dialog-title"
            >
              <div className={classes.dialogTitleContainer}>
                <DialogTitle id="responsive-dialog-title">
                  {props.title}
                </DialogTitle>

                <div className={classes.dialogCloseButton}>
                  <IconButton
                    onClick={panelDown}
                    color="inherit"
                    aria-label="close panel"
                    component="span"
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <Divider />

              <DialogContent
                className={classes.dialogContent}
                style={{
                  padding: props.padding ? props.padding * 8 : 0,
                  maxWidth: props.maxWidth ? props.maxWidth : undefined,
                }}
              >
                {props.children}
              </DialogContent>
            </Dialog>
          )}
        </React.Fragment>
      )}
    </PanelsContext.Consumer>
  );
}
