import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chips from "@Components/database/info/dataChips";

import InfoList, {
  getRoomListDataFromTatemono,
  getArchiListDataFromTatemono,
} from "@Components/database/info/infoList";
import Carousel, { ImageData } from "@Components/database/info/carousel";
import { TatemonoUni } from "@Types/database";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1000,
    backgroundColor: theme.palette.background.paper,
  },
  infoSection: {
    padding: theme.spacing(2),
  },
  chips: {
    margin: `${theme.spacing(1)}px 0`,
  },
}));

export interface TabData {
  images: ImageData[];
  tatemono: TatemonoUni;
}

interface TabsProps {
  data?: TabData[];
  fullScreen?: boolean;
}

export default function ScrollableTabsButtonAuto(props: TabsProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(0);
  }, [props.data]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          {props.data
            ? props.data.map((item, i) => {
                return (
                  <Tab
                    key={i}
                    label={item.tatemono.name || "建物情報"}
                    {...a11yProps(i)}
                  />
                );
              })
            : null}
        </Tabs>
      </AppBar>

      {props.data
        ? props.data.map((item, i) => {
            return (
              <TabPanel key={i} value={value} index={i}>
                <Grid container justifyContent="flex-start">
                  {item.images.length ? (
                    <Grid
                      key={0}
                      item
                      xs={12}
                      sm={6}
                      md={props.fullScreen ? 6 : 12}
                    >
                      <Carousel
                        height={props.fullScreen ? 320 : 240}
                        data={item.images}
                      />
                    </Grid>
                  ) : null}

                  <Grid
                    key={1}
                    item
                    xs={12}
                    sm={6}
                    md={props.fullScreen ? 6 : 12}
                    className={classes.infoSection}
                  >
                    <InfoList
                      header="部屋情報"
                      data={getRoomListDataFromTatemono(item.tatemono)}
                    />

                    <div className={classes.chips}>
                      <Chips tatemonoInfo={item.tatemono} />
                    </div>

                    <InfoList
                      header="建築詳細"
                      data={getArchiListDataFromTatemono(item.tatemono)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })
        : null}
    </div>
  );
}
