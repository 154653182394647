import React, { ReactNode, useState, useEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import useDarkMode from "use-dark-mode";
import Image from "next/image";
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";

import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CallReceivedIcon from "@material-ui/icons/CallReceived";

import CustomPaperMotionDiv, {
  getMidY,
  findClosestTarget,
  DragTarget,
} from "@Components/motion/paper";

export const useMutationObserver = (
  ref: React.MutableRefObject<any>,
  callback: () => void,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  React.useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragBoundary: {
      pointerEvents: "none",
      position: "absolute",
      height: "100%",
      width: "100%",
    },
    // Mobile
    panelRoot: {
      flexGrow: 1,
    },
    dialogtitle: {
      padding: 0,
      color: theme.palette.primary.main,
    },
    titleIcon: {
      border: "3px solid #fff;",
      width: theme.spacing(8),
      height: theme.spacing(8),
      top: 16,
      zIndex: 100,
    },
    navBar: {
      position: "absolute",
      top: 3,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    titleContainer: {
      [theme.breakpoints.down("sm")]: { marginLeft: theme.spacing(2) },
      display: "flex",
      justifyContent: "space-between",
      lineHeight: "48px",
      height: 48,
      textAlign: "center",
      bottom: 0,
    },
    titleText: {
      display: "flex",
      alignItems: "center",
      width: "calc(100% - 96px)",
      textAlign: "start",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    paper: {
      height: "calc(100% - 60px)",
      zIndex: theme.zIndex.modal,
      width: 480,
      left: 16,
      bottom: 16,
      backgroundColor: theme.palette.background.default,
      position: "fixed",
      pointerEvents: "all",
      borderRadius: 4,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 44,
        height: "100%",
        borderRadius: 8,
        left: 0,
        bottom: 0,
        width: "100%",
      },
    },
    content: {
      padding: 0,
      height: "calc(100% - 60px)",
      overflowWrap: "break-word",
    },
    dragIcon: {
      color: theme.palette.text.disabled,
      // marginLeft: 8,
      alignSelf: "center",
    },
  })
);

export type DialogSize = "mid" | "full";
const offset = 49;

export interface DialogProps {
  open: boolean;
  children?: ReactNode;
  title: string;
  id: string;
  onDragEnd?: () => void;
  onClose?: () => void;
  onReset?: () => void;
  avatar?: string;
}

export default function SimpleDialog(props: DialogProps) {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const darkMode = useDarkMode();

  const [dialogSize, setDialogSize] = useState<DialogSize>("mid");
  const panelUp = () => {
    setDialogSize("full");
  };
  const panelDown = () => {
    setDialogSize("mid");
  };

  const [y, setY] = useState(getMidY(offset));
  useEffect(() => {
    switch (dialogSize) {
      case "full":
        setY(0);
        break;
      case "mid":
        setY(getMidY(offset));
        break;
    }
  }, [dialogSize]);

  const constraintsRef = useRef(null);
  const handleDragEnd = (event: any, info: any) => {
    if (typeof window !== "undefined") {
      const lastY = info.point.y;

      const targets = [
        { size: "full", y: 0 },
        { size: "mid", y: getMidY(offset) },
        { size: "hide", y: window.innerHeight as number },
      ];
      const closest = findClosestTarget(lastY, targets as DragTarget[]);

      setY(closest.y);

      if (closest.size === "hide") {
        if (props.onClose) props.onClose();
        setDialogSize("mid");
      } else {
        setDialogSize(closest.size as DialogSize);
      }
    }
  };

  const handleClose = () => {
    if (props.onClose) props.onClose();
  };
  useEffect(() => {
    if (props.open) {
      setY(getMidY(offset));
      setDialogSize("mid");
    }
  }, [props.open]);

  const paperRef = React.useRef(null);
  useMutationObserver(
    paperRef,
    () => {
      if (paperRef && paperRef.current) {
        const elm = paperRef.current as HTMLDivElement;
        elm.style.height = mobile ? "100%" : "calc(100% - 60px)";
      }
    },
    {
      attributes: true,
      characterData: false,
      childList: false,
      subtree: false,
    }
  );

  return (
    <React.Fragment>
      <div ref={constraintsRef} className={classes.dragBoundary}></div>

      <AnimatePresence exitBeforeEnter>
        {props.open ? (
          <CustomPaperMotionDiv
            innerRef={paperRef}
            id={`${props.id}-dialog`}
            className={classes.paper}
            drag={mobile ? "y" : true}
            dragElastic={0.1}
            dragConstraints={mobile ? { top: 0 } : constraintsRef}
            dragMomentum={false}
            onDragEnd={mobile ? (handleDragEnd as any) : undefined}
            initial={{ y: mobile ? y : undefined, opacity: 0 }}
            animate={{ y: mobile ? y : undefined, opacity: 1 }}
            exit={{ y: mobile ? y : undefined, opacity: 0 }}
          >
            <DialogTitle
              disableTypography
              style={{ cursor: "move" }}
              className={classes.dialogtitle}
            >
              {mobile ? (
                <div className={classes.navBar}>
                  <Image
                    src={`/nav-bar-${darkMode.value ? "dark" : "light"}.svg`}
                    alt="Nav Bar"
                    width={48}
                    height={6}
                  />
                </div>
              ) : null}

              <div className={classes.titleContainer}>
                {mobile ? null : (
                  <DragIndicatorIcon className={classes.dragIcon} />
                )}

                <div
                  className={classes.titleText}
                  onClick={mobile ? panelUp : undefined}
                >
                  <Typography variant="h6">{props.title}</Typography>
                </div>

                {props.avatar ? (
                  <Avatar
                    alt={props.title}
                    src={props.avatar}
                    className={classes.titleIcon}
                  />
                ) : null}

                {mobile ? (
                  <IconButton color="inherit" aria-label="Open in New Tab">
                    <OpenInNewIcon />
                  </IconButton>
                ) : (
                  <IconButton color="inherit" aria-label="Pin to Bottom">
                    <CallReceivedIcon />
                  </IconButton>
                )}

                {dialogSize === "full" ? (
                  <IconButton
                    onClick={panelDown}
                    color="inherit"
                    aria-label="Fold Panel"
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={handleClose}
                    color="inherit"
                    aria-label="Close Panel"
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                )}
              </div>
            </DialogTitle>

            <DialogContent dividers className={classes.content}>
              {props.children}
            </DialogContent>
          </CustomPaperMotionDiv>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  );
}
