import React from "react";
import Carousel from "react-material-ui-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Skeleton from "@material-ui/lab/Skeleton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import ImageDialog from "./imageDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      objectFit: "cover",
      backgroundColor: "#999999",
      width: "100%",
    },
    caption: { textAlign: "center", padding: theme.spacing(1) },
    button: {
      float: "right",
      borderRadius: "unset",
    },
    skeleton: {
      color:
        theme.palette.type === "dark" ? "#fff" : theme.palette.primary.main,
      maxWidth: "unset",
    },
    overlay: {
      visibility: "visible",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    imageButton: {
      marginTop: 24,
    },
  })
);

export interface ImageData {
  src: string;
  thumbSrc?: string;
  largeSrc?: string;
  caption: string;
  href?: string;
}

interface Props {
  height?: number;
  link?: boolean;
  data: Array<ImageData>;
}

export default function Example(props: Props): React.ReactElement {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const image = props.data[index];

  return (
    <React.Fragment>
      {image ? (
        <ImageDialog
          open={open}
          onClose={handleClose}
          data={{
            src: image.largeSrc || image.src,
            thumbSrc: image.thumbSrc || image.src,
            caption: image.caption,
          }}
        />
      ) : null}

      {props.data.length ? (
        <Carousel
          autoPlay={false}
          indicators={props.data.length > 1}
          navButtonsAlwaysVisible={props.data.length <= 1 ? false : true}
          navButtonsAlwaysInvisible={props.data.length <= 1}
          onChange={(index: number) => {
            setIndex(index);
          }}
        >
          {props.data.map((item, i) => (
            <Card key={i}>
              {props.link ? (
                <CardActionArea href={item.href || ""}>
                  <Paper>
                    <LazyLoadImage
                      className={classes.image}
                      src={item.src}
                      placeholderSrc={item.thumbSrc}
                      height={props.height}
                      wrapperProps={{ style: { width: "100%" } }}
                    />
                  </Paper>

                  <div className={classes.caption}>
                    <Typography variant="body2">{item.caption}</Typography>
                  </div>
                </CardActionArea>
              ) : (
                <CardActionArea onClick={handleClickOpen}>
                  <Paper>
                    <LazyLoadImage
                      className={classes.image}
                      src={item.src}
                      placeholderSrc={item.thumbSrc}
                      height={props.height}
                      wrapperProps={{ style: { width: "100%" } }}
                    />
                  </Paper>

                  <div className={classes.caption}>
                    <Typography variant="body2">{item.caption}</Typography>
                  </div>
                </CardActionArea>
              )}
            </Card>
          ))}
        </Carousel>
      ) : (
        <div>
          <Skeleton
            variant="rect"
            animation={false}
            height={props.height || 100}
            className={classes.skeleton}
          >
            <div className={classes.overlay}>
              <Typography variant="subtitle2">
                ときやどでは、皆様の写真を募集しています。
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.imageButton}
                endIcon={<OpenInNewIcon />}
                href="https://forms.gle/1gPuBqttZ9YQiT7t6"
                target="_blank"
              >
                写真を提供する
              </Button>
            </div>
          </Skeleton>
        </div>
      )}
    </React.Fragment>
  );
}
