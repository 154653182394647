import React from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { YadoUni, TatemonoUni } from "../../../types/database";
import { yadoIcons, tatemonoIcons } from "../../../lib/icons";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "wrap",
      justifyContent: "center",
      margin: `${theme.spacing(2)}px 0`,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

interface Props {
  tatemonoInfo?: TatemonoUni;
  yadoInfo?: YadoUni;
}

export default function ChipsArray(props: Props): React.ReactElement {
  const theme = useTheme();
  const classes = useStyles();

  let chipsArray: { label: string; icon: React.ReactElement; link?: string }[] =
    [{ label: "", icon: <p></p> }];

  if (props.tatemonoInfo) {
    const tatemono = props.tatemonoInfo;
    chipsArray = [];

    if (props.tatemonoInfo.structure)
      chipsArray.push({
        label: props.tatemonoInfo.structure,
        icon: tatemonoIcons.structure,
      });

    if (props.tatemonoInfo.storeys)
      chipsArray.push({
        label: `${props.tatemonoInfo.storeys}階建て`,
        icon: tatemonoIcons.storeys,
      });

    if (tatemono.registration)
      tatemono.registration.forEach((item: any) => {
        chipsArray.push({
          label: item,
          icon: tatemonoIcons.registration,
        });
      });
  } else if (props.yadoInfo) {
    const yado = props.yadoInfo;
    chipsArray = [];

    if (yado.prefecture)
      chipsArray.push({
        label: yado.prefecture,
        icon: yadoIcons.prefecture,
        link: `/search?prefecture=${yado.prefecture}`,
      });

    if (yado.onsenName)
      chipsArray.push({
        label: "温泉",
        icon: yadoIcons.onsen,
        link: "/search?hotsprings=true",
      });

    if (yado.operation)
      chipsArray.push({
        label: yado.operation,
        icon: yadoIcons.operation,
        link: `/search?operation=${yado.operation}`,
      });
  }

  return (
    <div className={classes.root}>
      {chipsArray.map((item, i) => {
        return item.label ? (
          <Chip
            key={i}
            className={classes.chip}
            component="a"
            color="primary"
            variant="outlined"
            icon={item.icon}
            label={item.label}
            clickable
          />
        ) : null;
      })}
    </div>
  );
}
