import React, { ReactNode, useEffect, useState, useRef } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import BookLink from "@Components/database/bookLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 600,
      },
    },
    sectionTitle: {
      padding: "60px 0",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        padding: 60,
      },
    },
    sectionDesc: {
      [theme.breakpoints.up("sm")]: {
        // maxHeight: 200,
      },
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "justify",
    },
    descTypo: { fontFamily: "Shippori Mincho B1", fontWeight: 400 },
    button: {
      width: 200,
      // display: "block",
      margin: "60px auto",
      pointerEvents: "all",
    },
  })
);

export interface HeroContentProps {
  background?: boolean;
  color?: string;
  title: string | ReactNode;
  desc: string;
  button?: string;
  link?: string;
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export default function HeroContents(props: HeroContentProps) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [clientReady, setClientReady] = useState<boolean>(false);

  useEffect(() => {
    setClientReady(true);
  }, []);

  return clientReady ? (
    <Container
      className={classes.container}
      style={{
        color: props.color ? props.color : theme.palette.primary.main,
      }}
    >
      <div>
        <div className={classes.sectionTitle}>
          {typeof props.title === "string" ? (
            <Typography variant="h5">{props.title}</Typography>
          ) : (
            props.title
          )}
        </div>

        <div className={classes.sectionDesc}>
          <Typography
            className={classes.descTypo}
            variant="body1"
            style={
              mobile
                ? {
                    fontSize: "0.9rem",
                    lineHeight: 2.2,
                  }
                : {
                    fontSize: "1.1rem",
                    lineHeight: 2.4,
                  }
            }
          >
            {props.desc}
          </Typography>
        </div>

        {props.children ? props.children : null}

        {props.button ? (
          <Button
            className={classes.button}
            variant="outlined"
            color="inherit"
            size="large"
            href={props.link}
            startIcon={props.icon}
            onClick={() => props.onClick?.()}
          >
            {props.link || props.onClick ? props.button : "準備中"}
          </Button>
        ) : null}
      </div>
    </Container>
  ) : null;
}
