import React, { useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CardMedia from "@material-ui/core/CardMedia";

import {
  FormSection,
  FormValue,
  formData,
  getPrice,
  getRangeText,
  getState,
} from "@Lib/search";

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardRoot: {
      backgroundColor: theme.palette.background.default,
      position: "relative",
      height: "100%",
      padding: theme.spacing(2),
    },
    sectionName: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
    formRoot: {
      // display: 'flex',
    },
    formControl: {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
      minWidth: 140,
    },
    slider: {
      margin: theme.spacing(1),
      width: "auto",
    },
    helperText: {
      margin: "8px 0",
    },
    tooltip: {
      maxWidth: 120,
    },
    buttonRoot: {
      paddingTop: theme.spacing(2),
    },
    resetButton: {
      marginRight: 16,
    },
    branding: {
      height: 24,
      backgroundSize: "contain",
      margin: `${theme.spacing(2)}px 0 auto`,
      position: "inherit",
      [theme.breakpoints.up("sm")]: {
        margin: `${theme.spacing(2)}px auto`,
        position: "absolute",
        left: 0,
        width: "100%",
        bottom: 0,
      },
    },
    menuItem: {
      // backgroundColor: theme.palette.background.paper,
    },
  })
);

const listMax = 5;

export interface FilterCardsProps {
  help?: boolean;
  // searchParams: string;
  onChange: (value: FormValue) => void;
  fullScreen?: boolean;
}

export default function SimpleDialogDemo(
  props: FilterCardsProps
): React.ReactElement {
  const classes = useStyles();
  const [state, setState] = React.useState(getState(formData));

  const handleReset = () => {
    const newState = getState(formData);
    setState(newState);
    props.onChange(newState);
  };

  const handleCheckbox = (value: boolean, name: string) => {
    const newState = { ...state, [name]: { selected: value } };
    setState(newState);
    props.onChange(newState);
  };

  const handleSlider = (value: number[], name: string) => {
    // const arr = value as number[]

    if (value[0] !== value[1]) {
      const newState = {
        ...state,
        [name]: { range: value },
      };
      setState(newState);
      props.onChange(newState);
    }
  };

  const handleSelect = (value: string[], name: string) => {
    const newState = {
      ...state,
      [name]: { list: value },
    };
    setState(newState);
    props.onChange(newState);
  };

  return (
    <Grid container spacing={2}>
      {formData.map((section: FormSection, sectionIndex: number) => (
        <Grid key={sectionIndex} item xs={12} md={props.fullScreen ? 4 : 12}>
          <Card className={classes.cardRoot}>
            <Typography variant="h6">{section.label}</Typography>
            <Divider />
            <div className={classes.formRoot}>
              {section.inputs.map((input, inputIndex) => (
                <FormControl
                  variant="filled"
                  key={inputIndex}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">{input.label}</FormLabel>

                  {input.type === "checkbox" ? (
                    <FormGroup>
                      {input.buttons.map((buttonData, buttonIndex) => (
                        <React.Fragment key={buttonIndex}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={buttonData.disabled}
                                checked={state[buttonData.fieldName].selected}
                                onChange={(e, value) => {
                                  const selected = value as boolean;
                                  handleCheckbox(
                                    selected,
                                    buttonData.fieldName
                                  );
                                }}
                                name={buttonData.label}
                                color="secondary"
                              />
                            }
                            label={buttonData.label}
                          />
                          {buttonData.disabled ? (
                            <FormHelperText className={classes.helperText}>
                              準備中
                            </FormHelperText>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </FormGroup>
                  ) : input.type === "slider" ? (
                    <React.Fragment>
                      <FormHelperText className={classes.helperText}>
                        {input.disabled
                          ? "準備中"
                          : getRangeText(
                              state[input.fieldName].range,
                              input.values.unit
                            )}
                      </FormHelperText>
                      <Slider
                        disabled={input.disabled}
                        className={classes.slider}
                        value={state[input.fieldName].range}
                        onChange={(e, value) => {
                          const range = value as number[];
                          handleSlider(range, input.fieldName);
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby={input.label}
                        valueLabelFormat={(value) => {
                          if (input.values.unit === "円") {
                            return getPrice(value);
                          } else return `${value}`;
                        }}
                        step={input.values.step}
                        marks={input.marks}
                        min={input.values.min}
                        max={input.values.max}
                      />
                    </React.Fragment>
                  ) : input.type === "select" ? (
                    <React.Fragment>
                      <Select
                        labelId="label"
                        disabled={input.disabled}
                        multiple
                        value={state[input.fieldName].list}
                        onChange={(event) => {
                          const list = event.target.value as string[];
                          if (list.length <= listMax)
                            handleSelect(list, input.fieldName);
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                        MenuProps={{
                          style: { width: 120, minWidth: "auto" },
                        }}
                      >
                        {input.list.map((item, i) => (
                          <MenuItem
                            key={i}
                            value={item}
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={
                                (state[input.fieldName].list || []).indexOf(
                                  item
                                ) > -1
                              }
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText className={classes.helperText}>
                        {input.disabled
                          ? "準備中"
                          : `複数選択可 ${
                              input.list.length > listMax
                                ? `${listMax}件まで`
                                : ""
                            }`}
                      </FormHelperText>
                    </React.Fragment>
                  ) : (
                    <span></span>
                  )}
                </FormControl>
              ))}
            </div>
          </Card>
        </Grid>
      ))}
      {/* <Grid key="b" item xs={12} sm={6} md={4}>
          <Card className={classes.cardRoot}>
            <Typography variant="h6">検索</Typography>
            <Divider />
            <Typography variant="body1">ヒット：_件</Typography>

            <div className={classes.buttonRoot}>
              <Button
                className={classes.resetButton}
                variant="contained"
                onClick={handleReset}
              >
                リセット
              </Button>
              <Button
                href={"/app/results.html?" + props.searchParams}
                variant="contained"
                color="secondary"
              >
                検索
              </Button>
            </div>
            <CardMedia
              className={classes.branding}
              image="/app/images/algolia/search-by-algolia-light-background.svg"
              title="Search by Algolia"
            />
          </Card>
      </Grid> */}
    </Grid>
  );
}
