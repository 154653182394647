import React, { ReactNode, useEffect, useState, useRef } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "next/link";
import { Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const book_url =
  "https://book.gakugei-pub.co.jp/gakugei-book/9784761528850/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookCoverMobile: {
      zIndex: 9000,
      display: "flex",
      justifyContent: "space-evenly",
      position: "relative",
      top: -50,
      backgroundColor: "rgba(0,0,0,0.4)",
      color: "white",
      padding: 16,
      [theme.breakpoints.down("sm")]: { padding: 8 },
    },
    bookDesc: {
      paddingLeft: 16,
      textAlign: "start",
      lineHeight: "200%",
      display: "flex",
      flexDirection: "column",
    },
    button: {
      fontSize: 16,
      justifyContent: "center",
      flex: "auto",
      display: "flex",
      alignItems: "center",
    },
  })
);

export interface HeroContentProps {
  background?: boolean;
  color?: string;
  title: string | ReactNode;
  desc: string;
  button?: string;
  link?: string;
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

export default function HeroContents() {
  const classes = useStyles();
  const theme = useTheme();

  const [clientReady, setClientReady] = useState<boolean>(false);

  useEffect(() => {
    setClientReady(true);
  }, []);

  return clientReady ? (
    <Link href={book_url} passHref legacyBehavior>
      <a target="_blank" rel="noopener noreferrer">
        <div className={classes.bookCoverMobile}>
          <LazyLoadImage
            alt="ときを感じる お宿図鑑"
            //height={100}
            width={110}
            src="images/book_cover.jpg"
          />
          <div className={classes.bookDesc}>
            <Typography variant="h6">
              &nbsp;スケッチで解説する
              <br />
              『ときを感じるお宿図鑑』
              <br />
              &nbsp;5月発売開始！
            </Typography>
            <div className={classes.button}>
              詳細 & 予約はこちら&nbsp;
              <OpenInNewIcon />
            </div>
          </div>
        </div>
      </a>
    </Link>
  ) : null;
}
