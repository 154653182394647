import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Dialog from "@Components/database/dialog";
import Carousel, { ImageData } from "@Components/database/info/carousel";
import InfoList, {
  getInfoListDataFromYado,
} from "@Components/database/info/infoList";
import TatemonoTabs, { TabData } from "@Components/database/info/tatemonoTabs";
import Chips from "@Components/database/info/dataChips";
import Rating from "@Components/database/info/rating";
import { PanelsContext } from "@Pages/index";
import client from "@Config/graphCMSInit";
import { YadoUni } from "@Types/database";
import TatemonoCard from "@Components/database/info/tatemonoCard";
import DisplayAd from "@Components/ads/displayAd";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageSection: { padding: 0 },
    infoSection: { padding: theme.spacing(2) },
    cardsSection: {
      width: "100%",
    },
    cardsSectionTitle: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    map: { width: "100%" },
  })
);

export const GET_YADO_IMAGES = gql`
  query GetImages($id: Int!) {
    images(where: { yadoId: $id }, orderBy: csvRow_ASC) {
      title
      description
      copyright
      dateTaken
      imageFile {
        thumbnail: url(
          transformation: {
            image: { resize: { width: 400, height: 400, fit: clip } }
            document: { output: { format: webp } }
          }
        )
        url(
          transformation: {
            image: { resize: { width: 1000, height: 1000, fit: clip } }
            document: { output: { format: webp } }
          }
        )
      }
    }
  }
`;

export const GET_TATEMONO_IMAGES = gql`
  query GetImages($ids: [Int!]) {
    images(where: { tatemonoId_in: $ids }, orderBy: csvRow_ASC) {
      title
      description
      tatemonoId
      copyright
      dateTaken
      imageFile {
        thumbnail: url(
          transformation: {
            image: { resize: { width: 400, height: 400, fit: clip } }
          }
        )
        url(
          transformation: {
            image: { resize: { width: 1000, height: 1000, fit: clip } }
            document: { output: { format: webp } }
          }
        )
      }
    }
  }
`;

export interface QueryData {
  images: YadoImage[];
}

export interface YadoImage {
  title: string;
  description: string;
  copyright: string;
  dateTaken: string;
  imageFile: {
    thumbnail: string;
    url: string;
  };
}

export interface TatemonoImage {
  title: string;
  description: string;
  tatemonoId: number;
  copyright: string;
  dateTaken: string;
  imageFile: {
    thumbnail: string;
    url: string;
  };
}

export const imagesToImageData = (
  images: YadoImage[] | TatemonoImage[],
  locale: string
) => {
  return images.map((image) => {
    const date = new Date(image.dateTaken);
    const localeStr = locale === "ja" ? "ja-JP" : "en-US";
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
    };
    return {
      src: image.imageFile ? image.imageFile.thumbnail : "",
      largeSrc: image.imageFile ? image.imageFile.url : "",
      caption:
        `${image.description || image.title} (${date.toLocaleDateString(
          localeStr,
          options
        )} - ${image.copyright})` || "",
    } as ImageData;
  });
};

export default function InfoDialog() {
  const { t: t_database } = useTranslation("database");
  const classes = useStyles();
  const router = useRouter();
  const [yadoImageData, setYadoImageData] = useState<ImageData[]>([]);
  const [tabData, setTabData] = useState<TabData[]>([]);
  const {
    allTatemonos,
    setSelectedImage,

    selectedYado,
    setSelectedYado,
    setSelectedUser,
  } = useContext(PanelsContext);

  const { loading, error, data } = useQuery<QueryData>(GET_YADO_IMAGES, {
    client,
    variables: {
      id: selectedYado?.id,
    },
  });

  useEffect(() => {
    if (data) {
      const imageDataArray = imagesToImageData(
        data.images,
        router.locale || "ja"
      );
      setYadoImageData(imageDataArray);
      if (imageDataArray[0]) {
        const url = imageDataArray[0].largeSrc || imageDataArray[0].src;
        setSelectedImage(url);
      }
    }
  }, [data, router.locale]);

  useEffect(() => {
    if (
      allTatemonos.length &&
      selectedYado &&
      selectedYado.relatedTatemonoIds.length
    ) {
      const relTatemono = selectedYado.relatedTatemonoIds.map((tatemonoId) => {
        const match = allTatemonos.filter((tatemono) => {
          return tatemono.id === tatemonoId;
        });
        return match[0];
      });

      const tatemonoIds = relTatemono.map((tatemono) => {
        return tatemono.id;
      });

      client
        .query({
          query: GET_TATEMONO_IMAGES,
          variables: { ids: tatemonoIds },
        })
        .then((result) => {
          const dataArr = relTatemono.map((tatemono) => {
            const tatemonoImages = result.data.images as TatemonoImage[];

            const filtered = tatemonoImages.filter((image) => {
              return image.tatemonoId == tatemono.id;
            });
            const imageDataArray = imagesToImageData(
              filtered,
              router.locale || "ja"
            );

            return {
              images: imageDataArray,
              tatemono,
            } as TabData;
          });

          setTabData(dataArr);
        });
    } else {
      setTabData([]);
    }
  }, [allTatemonos, selectedYado, router.locale]);

  const handleClose = () => {
    setSelectedYado(undefined);
  };

  const [dialogOpen, setDialogOpen] = useState(!!selectedYado);
  useEffect(() => {
    setDialogOpen(!!selectedYado);
    if (selectedYado) {
      setSelectedUser(undefined);
    }
  }, [selectedYado]);

  const fullScreen = false;
  const enableTabs = true;

  return (
    <PanelsContext.Consumer>
      {(value) => {
        const yado =
          value.selectedYado ||
          ({
            id: 0,
            csvRow: 0,
            relatedTatemonoIds: [],
            rating: 0,
            name: "読み込み中...",
            nameAlt: "Loading...",
          } as YadoUni);
        return (
          <Dialog
            title={
              yado.onsenName
                ? `[${yado.onsenName}] ${yado.name}`
                : yado.name || ""
            }
            id="info"
            onClose={handleClose}
            open={dialogOpen}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={fullScreen ? 6 : 12}
                className={classes.imageSection}
              >
                <div>
                  <Carousel
                    height={fullScreen ? 320 : 240}
                    data={yadoImageData}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={fullScreen ? 6 : 12}
                className={classes.infoSection}
              >
                {yado.rating ? <Rating value={yado.rating} /> : null}

                <Chips yadoInfo={yado} />

                <InfoList
                  header={t_database("panels.info.contents.place_info")}
                  data={getInfoListDataFromYado(yado)}
                />
              </Grid>

              {enableTabs ? (
                <React.Fragment>
                  <TatemonoTabs data={tabData} fullScreen={fullScreen} />
                  <DisplayAd variant="horizontal" />
                </React.Fragment>
              ) : (
                <div className={classes.cardsSection}>
                  <div className={classes.cardsSectionTitle}>
                    <Typography variant="h6">建物一覧</Typography>
                  </div>

                  <Divider />

                  {tabData.map((data, i) => {
                    return <TatemonoCard key={i} data={data} />;
                  })}

                  <DisplayAd variant="in_feed" />
                </div>
              )}
            </Grid>
          </Dialog>
        );
      }}
    </PanelsContext.Consumer>
  );
}
