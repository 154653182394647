import React, { useState, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { PanelsContext } from "@Pages/index";
import ImageCard, {
  ImageCardProps,
} from "@Components/database/images/imageCard";
import { getImageUrl } from "@Config/graphCMSInit";

const width = 120;
const height = 120;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height,
      width,
      zIndex: theme.zIndex.modal + 1,
      left: 16,
      bottom: 16,
      position: "fixed",
    },
    button: {
      height: "100%",
      width: "100%",
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper,
    },
    image: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
      borderRadius: 8,
    },
    caption: {
      padding: theme.spacing(1),
      width: "100%",
      position: "absolute",
      bottom: 0,
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    closeButton: {
      zIndex: theme.zIndex.modal + 5,
      position: "absolute",
      top: 0,
      right: 0,
    },
  })
);

export interface PinProps {}

export default function Pin(props: PinProps) {
  const theme = useTheme();
  const classes = useStyles();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedYado } = useContext(PanelsContext);

  const [count, setCount] = useState(1);
  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <PanelsContext.Consumer>
      {(value) =>
        mobile ? null : null/*(
          <div>
            {[Array(count).keys()].map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={classes.root}>
                    <ButtonBase
                      className={classes.button}
                      onClick={handleClick}
                    >
                      <LazyLoadImage
                        className={classes.image}
                        alt="alt"
                        height={200}
                        width={200}
                        src={getImageUrl("UqdHrT9Q6OLpISZ2t7zB", {
                          width,
                          height,
                        })}
                      />
                      <div className={classes.caption}>
                        <Typography variant="body2">ピンした宿: {i}</Typography>
                      </div>
                    </ButtonBase>
                    <IconButton size="small" className={classes.closeButton}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        )*/
      }
    </PanelsContext.Consumer>
  );
}
