export interface FormSection {
  label: string;
  inputs: Array<
    | {
        label: string;
        type: "checkbox";
        help?: string;
        buttons: Array<{
          fieldName: string;
          label: string;
          disabled?: boolean;
        }>;
      }
    | {
        fieldName: string;
        label: string;
        disabled?: boolean;
        type: "select";
        help?: string;
        list: string[];
      }
    | {
        fieldName: string;
        label: string;
        disabled?: boolean;
        type: "slider";
        help?: string;
        values: { unit?: string; min: number; max: number; step: number };
        marks: Array<{ value: number; label: string }>;
      }
  >;
}

const formData: FormSection[] = [
  {
    label: "宿情報",
    inputs: [
      {
        fieldName: "rating",
        label: "DEEP度",
        type: "slider",
        help: "DEEP度の範囲を指定します",
        values: {
          min: 1,
          max: 5,
          step: 1,
        },
        marks: [
          {
            value: 1,
            label: "初心者",
          },
          {
            value: 5,
            label: "玄人",
          },
        ],
      },
      {
        fieldName: "operation",
        label: "営業形態",
        type: "select",
        list: ["ホテル", "旅館", "その他"],
      },
    ],
  },
  {
    label: "価格情報",
    inputs: [
      {
        fieldName: "price",
        label: "最低価格",
        type: "slider",
        help: "一泊の価格の範囲を指定します 1泊2食、2人を想定しています",
        values: {
          unit: "円",
          min: 0,
          max: 40000,
          step: 5000,
        },
        marks: [
          {
            value: 0,
            label: "0円",
          },
          {
            value: 40000,
            label: "4万円以上",
          },
        ],
      },
      {
        label: "支払い",
        type: "checkbox",
        help: "支払い方法の条件を指定します",
        buttons: [
          { disabled: true, fieldName: "acceptsCard", label: "カード対応" },
        ],
      },
    ],
  },
  {
    label: "宿泊情報",
    inputs: [
      {
        label: "寝具",
        type: "checkbox",
        help: "寝具の条件を指定します",
        buttons: [{ fieldName: "beds", label: "ベッドあり" }],
      },
      {
        label: "バリアフリー",
        type: "checkbox",
        help: "バリアフリー対応の有無を指定します",
        buttons: [{ disabled: false, fieldName: "barrierFree", label: "対応" }],
      },

      {
        label: "トイレ",
        type: "checkbox",
        help: "個別トイレの有無を指定します",
        buttons: [{ fieldName: "individualBathrooms", label: "個別あり" }],
      },
      {
        label: "温泉",
        type: "checkbox",
        help: "温泉の有無を指定します",
        buttons: [{ fieldName: "hotsprings", label: "あり" }],
      },
    ],
  },
  {
    label: "建物情報",
    inputs: [
      {
        label: "状態",
        type: "checkbox",
        help: "建物の状態の条件を指定します",
        buttons: [{ fieldName: "isOriginal", label: "原型に近い" }],
      },
      {
        fieldName: "conYear",
        label: "建築年",
        // disabled: true,
        type: "select",
        help: "建設年の元号・年号を選択します",
        list: ["江戸", "明治", "大正", "昭和"],
      },
      {
        fieldName: "structure",
        label: "構造",
        type: "select",
        help: "建物の構造を選択します",
        list: ["RC造", "木造", "土蔵造", "特殊造"],
      },
      {
        fieldName: "storeys",
        label: "階層",
        type: "select",
        help: "建物の階層数を選択します",
        list: ["1階", "2階", "3階", "4階", "5階以上"],
      },
    ],
  },
];

export interface FormValue {
  [name: string]: { selected?: boolean; list?: string[]; range?: number[] };
}

const getPrice = (value: number | undefined): string => {
  const fmt = new Intl.NumberFormat("ja-JP", {
    // notation: 'compact',
  });

  return fmt.format(value || 0) + "円";
};

const getRangeText = (array?: number[], unit?: string): string => {
  const arr = array?.map((value, i) => {
    if (unit === "円") {
      if (i === 0) return `${getPrice(value)}以上`;
      else if (i === 1) return `${getPrice(value)}以下`;
    } else {
      if (i === 0) return `${value}以上`;
      else if (i === 1) return `${value}以下`;
    }
  });
  const str = arr?.join(" ") || "";
  return str;
};

const getState = (forms: FormSection[]): FormValue => {
  const state: FormValue = {};
  forms.forEach((form) => {
    form.inputs.forEach((input) => {
      if (input.type === "checkbox") {
        input.buttons.forEach((button) => {
          state[button.fieldName] = { selected: false };
        });
      } else if (input.type === "select") {
        state[input.fieldName] = { list: [] };
      } else if (input.type === "slider") {
        state[input.fieldName] = {
          range: [input.values.min, input.values.max],
        };
      }
    });
  });

  return state;
};

export { formData, getPrice, getRangeText, getState };

// Search Data Format 2.0
/* import { Year, Structure } from "@monjo4610/tokiyado-common/types/schema";

interface Query {
  id: string;
  type: "text" | "tag" | "props";
  input: "string" | "checkbox" | "select" | "range";
  data:
    | { category: "free_word"; value: string }
    | { category: "tag"; value: string }
    | { category: "location"; value: string }
    | { category: "rating"; value: number[] }
    | { category: "operation"; value: string }
    | { category: "price"; value: number[] }
    | { category: "payments"; value: string }
    | { category: "beds"; value: string }
    | { category: "a11y"; value: string }
    | { category: "toilets"; value: string }
    | { category: "onsens"; value: string }
    | { category: "status"; value: string }
    | { category: "year"; value: Year }
    | { category: "structure"; value: string[] }
    | { category: "storeys"; value: number[] };
} */
