import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MapImage, { getMapURL } from "@Components/database/info/mapImage";
import { yadoIcons, tatemonoIcons } from "@Lib/icons";
import { YadoUni, TatemonoUni } from "@Types/database";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    item: {
      overflowWrap: "anywhere",
    },
  })
);

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export interface ListData {
  text: string;
  icon: React.ReactElement;
  link?: string;
}

export interface ListProps {
  header: string;
  data: ListData[];
}

export const getInfoListDataFromYado = (yado: YadoUni) => {
  return [
    {
      text: yado.address || "",
      icon: yadoIcons.address,
      link: getMapURL(yado.address, yado.name),
    },
    {
      text: yado.phoneNumber || "",
      icon: yadoIcons.phoneNumber,
      link: `tel:${yado.phoneNumber}`,
    },
    {
      text: yado.url || "ウェブサイトなし",
      icon: yadoIcons.url,
      link: yado.url,
    },
  ] as ListData[];
};

export const getFacilityListDataFromYado = (yado: YadoUni) => {
  const features: ListData[] = [];

  if (yado?.payment)
    features.push({
      text: yado?.payment,
      icon: yadoIcons.payment,
    });

  if (yado?.bathroom)
    features.push({
      text: yado?.bathroom,
      icon: yadoIcons.bathroom,
    });

  if (yado?.bed)
    features.push({
      text: yado?.bed,
      icon: yadoIcons.bed,
    });

  if (yado?.barrierFree)
    features.push({
      text: yado?.barrierFree,
      icon: yadoIcons.barrierFree,
    });

  return features;
};

export const getRoomListDataFromTatemono = (tatemono: TatemonoUni) => {
  return [
    {
      text: tatemono.roomCount
        ? tatemono.roomCount + "部屋"
        : tatemono.roomCountEst
        ? "約" + tatemono.roomCountEst + "部屋"
        : "部屋数情報なし",
      icon: tatemonoIcons.roomCount,
    },
    {
      text: tatemono.price ? `￥${tatemono.price}から` : "価格情報なし",
      icon: tatemonoIcons.price,
    },
  ];
};

export const getArchiListDataFromTatemono = (tatemono: TatemonoUni) => {
  return [
    {
      text: tatemono.accommodation
        ? "使用状況: " + tatemono.accommodation
        : "使用情報なし",
      icon: tatemonoIcons.accommodation,
    },
    {
      text: tatemono.conPeriod
        ? "建設: " + tatemono.conPeriod
        : tatemono.conYear
        ? "建設: " + tatemono.conYear + "年"
        : tatemono.conYearEst
        ? "建設: " + tatemono.conYearEst + "年代"
        : "建設情報なし",
      icon: tatemonoIcons.construction,
    },
    {
      text:
        (tatemono.state
          ? tatemono.state
          : tatemono.renYear
          ? "改修: " + tatemono.renYear + "年"
          : tatemono.renYearEst
          ? "改修: " + tatemono.renYearEst + "年代"
          : "改修情報なし") || "",
      icon: tatemonoIcons.renovation,
    },
  ];
};

export default function InteractiveList(props: ListProps): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {props.header}
          </ListSubheader>
        }
        dense={true}
      >
        {props.data.map((item, i: number) =>
          item.link ? (
            <ListItemLink
              key={i}
              className={classes.item}
              href={item.link}
              target={item.link.startsWith("tel:") ? undefined : "_blank"}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemLink>
          ) : (
            <ListItem key={i} className={classes.item}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
}
