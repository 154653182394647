import React, { useContext } from "react";
import { useTranslation } from "next-i18next";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Masonry from "react-masonry-css";
import { useQuery, gql } from "@apollo/client";
import ImageCard, { ImageCardProps } from "./imageCard";
import Panel from "../panel";
import { PanelsContext } from "../../../pages/index";
import client from "../../../config/graphCMSInit";
import { useEffect } from "react";
import BookLink from "@Components/database/bookLink";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const GET_IMAGES = gql`
  query GetImages($ids: [Int!]) {
    images(where: { yadoId_in: $ids }, orderBy: updatedAt_DESC) {
      id
      title
      imageFile {
        width
        height
        url(
          transformation: {
            image: { resize: { width: 400, height: 400, fit: clip } }
            document: { output: { format: webp } }
          }
        )
      }
      type
      attributes
      yadoId
      tatemonoId
    }
  }
`;

interface ImageData {
  id: string;
  title: string;
  imageFile: {
    width: number;
    height: number;
    url: string;
  };
  type: string;
  attributes: string;
  yadoId?: number;
  tatemonoId?: number;
}

const breakpointColumns = {
  default: 4,
  1600: 3,
  800: 2,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      display: "flex",
      marginLeft: -theme.spacing(1),
      width: "auto",
    },
    column: {
      paddingLeft: theme.spacing(1),
      backgroundClip: "padding-box",
    },
    image: {
      marginBottom: theme.spacing(1),
    },
    linkRoot: {
      position: "relative",
      marginBottom: 8,
      top: 50,
    },
  })
);

export const shuffleArray = (array: any[]) => {
  const temp = [...array];
  for (let i = temp.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [temp[i], temp[j]] = [temp[j], temp[i]];
  }
  return temp;
};

export default function SimpleDialog() {
  const { t: t_database } = useTranslation("database");
  const classes = useStyles();
  const [posts, setPosts] = React.useState<ImageCardProps[]>([]);
  const { setImageCount, visibleYados } = useContext(PanelsContext);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const idlist = visibleYados.map((yado) => {
    return yado.id;
  });

  const { loading, error, data } = useQuery(GET_IMAGES, {
    client,
    variables: {
      ids: idlist,
    },
  });

  useEffect(() => {
    if (!loading) {
      setPosts([]);

      if (data && data.images.length) {
        const imageArr = [...data.images];
        const shuffledArr = shuffleArray(imageArr);

        const postListTemp = imageArr.map((image: ImageData) => {
          return {
            alt: image.title || "",
            src: image.imageFile ? image.imageFile.url : "",
            caption: image.title || "",
            type: image.type,
            yid: image.yadoId || undefined,
            tid: image.tatemonoId || undefined,
            width: image.imageFile ? image.imageFile.width : 200,
            height: image.imageFile ? image.imageFile.height : 200,
          } as ImageCardProps;
        });

        setImageCount(postListTemp.length);
        setPosts(postListTemp);
      } else {
        setImageCount(0);
      }
    }
  }, [data]);

  const count = posts.length;
  const max = 100;

  return (
    <PanelsContext.Consumer>
      {(value) => (
        <Panel
          id="images"
          title={
            count >= max
              ? t_database("panels.images.titleMore", { max })
              : t_database("panels.images.title", { count })
          }
          maxWidth={900}
          padding={1}
        >
          {value.openPanel === "images" ? (
            <React.Fragment>
              {mobile ? (
                <div className={classes.linkRoot}>
                  <BookLink />
                </div>
              ) : null}
              <Masonry
                breakpointCols={
                  value.panelSize === "full" ? breakpointColumns : 2
                }
                className={classes.grid}
                columnClassName={classes.column}
              >
                {posts.map((post: ImageCardProps, i) => (
                  <div className={classes.image} key={i}>
                    <ImageCard image={post} />
                  </div>
                ))}
              </Masonry>
            </React.Fragment>
          ) : null}
        </Panel>
      )}
    </PanelsContext.Consumer>
  );
}
