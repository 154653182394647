import React from "react";
import Rating from "@material-ui/lab/Rating";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      margin: `${theme.spacing(2)}px 0`,
    },
    rating: {
      color: theme.palette.secondary.main,
      verticalAlign: "middle",
    },
  })
);

interface Props {
  value: number;
}

export default function CustomizedRatings(props: Props): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body2" component="span">
        DEEP度：
      </Typography>
      <Rating
        className={classes.rating}
        name="read-only"
        value={props.value}
        readOnly
        icon={<FiberManualRecordIcon fontSize="inherit" />}
      />
    </div>
  );
}
