import React, { useState, useContext, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useRouter } from "next/router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";

import { PanelsContext } from "../../../pages/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
    },
    image: {
      width: "100%",
      objectFit: "contain",
      height: "auto",
    },
    caption: {
      padding: theme.spacing(1),
      width: "100%",
      position: "absolute",
      bottom: 0,
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  })
);

export interface ImageCardProps {
  alt: string;
  src?: string;
  height: number;
  width: number;
  thumbSrc?: string;
  caption: string;
  type: string;
  yid?: number;
  tid?: number;
}

export default function ImgMediaCard(props: {
  image: ImageCardProps;
}): React.ReactElement {
  const classes = useStyles();
  const router = useRouter();
  const { setSelectedYado, setOpenPanel, allYados } = useContext(PanelsContext);

  const handleCardClick = (image: ImageCardProps) => {
    if (image.yid) {
      const selected = allYados.find((yado) => {
        return yado.id === image.yid;
      });
      if (selected) {
        setSelectedYado(selected);

        router.push(`?yado=${selected.id}`, undefined, {
          shallow: true,
        });

        // setOpenPanel("info");
      }
    } else console.log(image);
  };

  let link = "";
  if (props.image.yid) link = `/app/entry.html?yid=${props.image.yid}`;
  else if (props.image.tid) link = `/app/entry.html?tid=${props.image.tid}`;

  return (
    <Card className={classes.root}>
      <CardActionArea
        /*href={link}*/ onClick={() => handleCardClick(props.image)}
      >
        <LazyLoadImage
          className={classes.image}
          alt={props.image.alt}
          height={props.image.height}
          width={props.image.width}
          src={props.image.src}
        />
        <div className={classes.caption}>
          <Typography variant="body2">{props.image.caption}</Typography>
        </div>
      </CardActionArea>
    </Card>
  );
}
