import React, { useEffect, useState } from "react";
import Image from "next/image";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1000,
      backgroundColor: "#fff",
      flexDirection: "column",
    },
    linear: {
      width: 320,
    },
  })
);

interface BackdropProps {
  progress: number;
  max: number;
}

export default function SimpleBackdrop(props: BackdropProps) {
  const classes = useStyles();
  const { progress, max } = props;
  const [open, setOpen] = useState(true);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent((props.progress / props.max) * 100);
    if (props.progress >= props.max) setOpen(false);
    else setOpen(true);
  }, [props.progress]);

  return (
    <Backdrop
      appear={false}
      className={classes.backdrop}
      open={open}
      transitionDuration={1000}
    >
      <div>
        <Image
          priority
          src="/logo/logo_colored.svg"
          alt="ときやど"
          width={120}
          height={40}
        />
        <Image
          src="/logo/logo_long.svg"
          alt="時を感じる宿に泊まろう"
          width={200}
          height={40}
        />
      </div>
      <div className={classes.linear}>
        <LinearProgress variant="determinate" value={percent} />
      </div>
    </Backdrop>
  );
}
