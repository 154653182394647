import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { ImageData } from "./carousel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    backdrop: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    paper: {
      margin: 0,
    },
    close: {
      position: "fixed",
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: "#fff",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "calc(100vh - 96px)",
    },
    caption: {
      height: theme.spacing(3),
      textAlign: "center",
    },
  })
);

export interface ImageDialogProps {
  open: boolean;
  onClose: () => void;
  data: ImageData;
}

export default function SimpleDialogDemo(
  props: ImageDialogProps
): React.ReactElement {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      BackdropProps={{ className: classes.backdrop }}
      PaperProps={{ className: classes.paper }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <IconButton
        className={classes.close}
        aria-label="close-dialog"
        onClick={handleClose}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <LazyLoadImage
        className={classes.image}
        src={props.data.src}
        placeholderSrc={props.data.thumbSrc}
      />
      <div className={classes.caption}>
        <Typography variant="body2">{props.data.caption}</Typography>
      </div>
    </Dialog>
  );
}
