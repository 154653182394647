import React, { useState, useEffect, useRef } from "react";
import { motion, useTransform, useElementScroll } from "framer-motion";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Hero from "@Components/index/hero";
import { getImageUrl } from "@Config/graphCMSInit";
import Logo from "@Components/common/logo";

import BookLink from "@Components/database/bookLink";

const imageWidth = 1000;
const imageHeight = 1000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: { position: "fixed", top: 0, left: 0 },
    container: {
      position: "fixed",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1500,
      overflowY: "scroll",
    },
    overlay: {
      position: "relative",
      height: "200%",
      width: "100%",
    },
    hero: {
      right: 0,
      position: "fixed",
      width: "100%",
      height: "100%",
    },
    linkSection: {
      zIndex: 2000,
      width: 400,
      position: "fixed",
      bottom: 7,
      right: 0,
    },
  })
);

interface SplashProps {
  onEnd: () => void;
}

export default function Splash(props: SplashProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ref = useRef(null!);
  const { scrollYProgress } = useElementScroll(ref);
  const scaleText = useTransform(scrollYProgress, [0, 1], [1, 0.94]);
  const scaleImg = useTransform(scrollYProgress, [0, 1], [1, 0.82]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
  const backgroundColor = useTransform(
    scrollYProgress,
    [0, 1],
    ["rgba(0, 0, 0, 0.5)", "rgba(0, 0, 0, 0.1)"]
  );

  const handleClose = () => {
    props.onEnd();
    setOpen(false);

    console.log("close");
  };

  useEffect(() => {
    return scrollYProgress.onChange((v) => {
      if (v > 0.99) {
        handleClose();
      }
    });
  }, [scrollYProgress]);

  return (
    <React.Fragment>
      <Backdrop open={open} ref={ref} className={classes.container}>
        <motion.div
          className={classes.hero}
          style={{
            scale: scaleImg,
            opacity,
          }}
        >
          <Hero
            background
            image={getImageUrl("UqdHrT9Q6OLpISZ2t7zB", {
              width: imageWidth,
              height: imageHeight,
            })}
          />
        </motion.div>
        <motion.div className={classes.overlay} style={{ backgroundColor }} />
        <motion.div
          className={classes.hero}
          style={{
            scale: scaleText,
            pointerEvents: "none",
          }}
        >
          <div>
            <Hero
              title={
                <React.Fragment>
                  <Logo forceTheme="dark" width={180} height={60} />
                  <Typography variant="h5">時を感じる宿に泊まろう。</Typography>
                </React.Fragment>
              }
              color="#FFF"
              desc={
                <React.Fragment>
                  <div>
                    ちかごろ、観光業は悲鳴を上げています。
                    <br />
                    <br />
                    日本伝統の美しい建造物を後世に伝えるためにも、力を合わせて疫病を乗り越えなければなりません。
                    自由に旅行が出来るまでの間にどうにか宿泊業を支援したいという思いから、
                    魅力的な古い宿を紹介する情報サイトを作ることにしました。
                    <br />
                    <br />
                    美しい宿は、意外にもたくさん散らばっています。
                    まずは、近所の魅力的な宿を見つけてみませんか。
                    みなさんに合った宿を発見し、良い体験をできることを願っています。
                  </div>
                </React.Fragment>
              }
              button="地図を見る"
              // link="/database"
              onClick={() => handleClose()}
              icon={<ExpandMoreIcon />}
            />
          </div>
        </motion.div>
      </Backdrop>
      {mobile ? null : (
        <div className={classes.linkSection}>
          {" "}
          <BookLink />
        </div>
      )}
    </React.Fragment>
  );
}
